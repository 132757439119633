<template>
    <div class='tab' v-show='isActive' ref="tab">
        <slot>No content for {{ title }}</slot>
    </div>
</template>

<script>
import { getHeight } from '@scripts/util/show-hide';

export default {
    props: ['title'],

    data() {
        return {
            isActive: true,
            tabHeight: 0,
        };
    },

    mounted() {
        this.setHeight();
    },

    watch: {
        isActive() {
            if (this.isActive) {
                this.setHeight();
                this.$parent.$emit('setHeight', this.tabHeight);
            }
        },
    },

    methods: {
        setHeight() {
            this.tabHeight = parseInt(getHeight(this.$refs.tab), 10);
        },
    },
};
</script>

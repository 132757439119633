<template>
    <div class="button-wrapper">
        <button class="btn btn--il btn--sm btn--wild-sand" @click="handleSidebar({type: 'user-face'})">
            <i class="icon-fitting-room"></i>
            <span>{{ $t('upload.photo') }}</span>
        </button>
        <button class="btn btn--il btn--sm btn--wild-sand" @click="handleSidebar({type: 'pupil-distance'})" v-show="userHasUploadedImage">
            <i class="icon-measure"></i>
            <span>{{ $t('message.pupil_distance') }}</span>
        </button>
        <button class="btn btn--il btn--sm spacing-none btn--wild-sand" @click="handleSidebar({type: 'models'})" v-show="!userHasUploadedImage">
            <i class="icon-face"></i>
            <span>{{ $t('models.short') }}</span>
        </button>
        <div class="share-wrapper">
            <button class="btn btn--sm btn--io share-action spacing-none btn--wild-sand" @click="shareAction" :disabled="!fittingRoom.glass.media">
                <i class="icon-share"></i>
                <i class="icon-close-alt"></i>
            </button>
            <Tooltip :content="$t('tooltip.share')" />
            <div class="share-buttons">
                <a :href="`https://www.facebook.com/sharer.php?u=${shareImage}`" class="btn btn--io btn--md share-button-facebook" target="_blank">
                    <i class="icon-facebook"></i>
                </a>
                <a :href="`https://twitter.com/intent/tweet?url=${shareImage}`" class="btn btn--io btn--md share-button-twitter" target="_blank">
                    <i class="icon-twitter"></i>
                </a>
                <a :href="`whatsapp://send/?text=${shareMessage()}`" class="btn btn--io btn--md share-button-whatsapp">
                    <i class="icon-whatsapp"></i>
                </a>
                <a :href="`mailto:?body=${shareMessage()}`" class="btn btn--io btn--md share-button-mail">
                    <i class="icon-envelope"></i>
                </a>
            </div>
        </div>
        <button class="btn btn--io btn--sm btn--reset btn--wild-sand" @click="resetLocalStorageData" v-if="getEnvironment === 'development'"><i class="icon-close"></i></button>
    </div>
</template>

<script>
import axios from 'axios';
// Components
import Tooltip from '@/components/tooltip';

export default {
    components: {
        Tooltip,
    },

    data() {
        return {
            shareOpen: false,
        };
    },

    computed: {
        showButton() {
            return (!this.isWebsiteOrigin && this.$route.path !== '/');
        },

        userHasUploadedImage() {
            return this.$store.getters.getUserHasUploadedImage;
        },

        fittingRoom() {
            return this.$store.getters.getFittingRoom;
        },

        shareImage() {
            return this.$store.getters.getShareImage;
        },
    },

    methods: {
        resetLocalStorageData() {
            this.$store.dispatch('resetLocalStorageData');
        },

        shareMessage() {
            let website = window.location.href;
            website = website.substring(0, website.indexOf('#'));
            let translatedMessage = {
                default: 'Hello!, I am trying on glasses online',
                photo: 'View my photo',
            };

            if (this.$i18n.locale === 'nl') {
                translatedMessage = {
                    default: 'Hallo!, Ik ben online brillen aan het passen op',
                    photo: 'Bekijk mijn foto',
                };
            } else if (this.$i18n.locale === 'de') {
                translatedMessage = {
                    default: 'Hallo!, ich probiere online eine Brille an',
                    photo: 'Sehen Sie sich mein Foto an',
                };
            }

            const message = `${translatedMessage.default} ${website}! ${translatedMessage.photo}: ${this.shareImage}`;

            return encodeURIComponent(message);
        },

        async shareAction(ev) {
            const { currentTarget: target } = ev;

            if (!this.shareOpen) {
                await this.fetchShareImage();
                this.shareOpen = true;
            } else {
                this.shareOpen = false;
            }

            const shareData = {
                title: 'BrilOnline',
                text: 'Ik ben online brillen aan het passen.',
                url: this.shareImage,
            };

            if (navigator.canShare && window.innerWidth <= 767.98) {
                try {
                    await navigator.share(shareData);
                } catch (err) {
                    throw new Error(err);
                }
            } else {
                target.parentElement.classList.toggle('active');
            }
        },

        addImageProcess() {
            return new Promise((resolve, reject) => {
                const parentComponent = this.$parent;
                const { imageWrapper } = parentComponent.$refs;
                const imageModel = document.querySelector('.cld-image.cld-image-loaded');
                const imageGlass = document.querySelector('.glasses-image');

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                const glassWidth = Number(imageWrapper.dataset.originalWidth);
                const glassHeight = Number(imageWrapper.dataset.originalHeight);
                const posX = Number(imageWrapper.dataset.originalX);
                const posY = Number(imageWrapper.dataset.originalY);
                const angle = Number(imageWrapper.dataset.angle);

                canvas.width = 952;
                canvas.height = 928;

                const baseImage = new Image();
                const glassImage = new Image();

                baseImage.crossOrigin = 'anonymous';
                glassImage.crossOrigin = 'anonymous';

                baseImage.addEventListener('load', () => {
                    context.drawImage(baseImage, 0, 0);
                }, false);
                baseImage.src = imageModel.src;

                glassImage.addEventListener('load', () => {
                    const centerX = glassWidth / 2;
                    const centerY = glassHeight / 2;
                    const imgDist = Math.min(glassWidth, glassHeight) / 2;

                    context.save();
                    context.translate(posX + centerX + 4, posY + imgDist);
                    context.rotate(angle);
                    context.drawImage(
                        glassImage,
                        -centerX,
                        -centerY,
                        glassWidth,
                        glassHeight,
                    );
                    context.restore();

                    resolve(canvas);
                }, false);

                setTimeout(() => {
                    glassImage.src = imageGlass.src;
                }, 150);

//                document.body.append(canvas);

                glassImage.onerror = reject;
            });
        },

        fetchShareImage() {
            if (this.shareImage) {
                return false;
            }

            return new Promise((resolve, reject) => {
                this.addImageProcess().then((canvas) => {
                    const imageData = canvas.toDataURL('image/png');
                    const formData = new FormData();
                    formData.append('upload_preset', 'o31sjhxg');
                    formData.append('timestamp', (+new Date()));
                    formData.append('file', imageData);

                    axios.post(
                        `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLD_NAME}/image/upload`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }).then((res) => {
                        if (res.status === 200) {
                            this.$store.dispatch('setShareImage', res.data.url);
                            resolve('Done');
                        }
                    }).catch((err) => {
                        reject(err);
                        throw new Error(err);
                    });
                });
            });
        },
    },
};
</script>

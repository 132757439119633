import axios from 'axios';

const widgetSettings = (document.querySelector('brilonline-widget') as HTMLElement);
const apiToken = widgetSettings.getAttribute('api-token');
const fittingRoom = widgetSettings.getAttribute('fitting-room');
const singleView = widgetSettings.getAttribute('single-view');
const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_ORDERS_BASEURL}/api/rooms/`,
    headers: {
        Authorization: 'Bearer ' + apiToken,
    },
});
const axiosInstanceOrder = axios.create({
    baseURL: `${process.env.VUE_APP_ORDERS_BASEURL}/`,
    headers: {
        Authorization: 'Bearer ' + apiToken,
    },
});

const api = {
    fetch: () => axiosInstance.get(
        `${fittingRoom}.json`,
    ),
    put: (putUrl: string, data: object) => axiosInstanceOrder.put(putUrl, data),
};

export { axiosInstance, axiosInstanceOrder, api, fittingRoom, singleView };

<template>
    <SidebarLayout type="favorites">
        <template #title>{{ $t('favorites.title') }}</template>

        <p class="body--small" v-html="data.descriptions.wishlist"></p>

        <div class="row">
            <div class="col-6" v-for="item in favorites" :key="item.slug">
                <product-card-simple :item="item" type="favorites" />
            </div>
        </div>

        <template #footer>
            <template v-if="userHasUploadedImage">
                <div class="text-center">
                    <span>{{ $t('favorites.face') }}</span>
                    <label class="switch">
                        <input type="checkbox" v-model="showOwnFace" @change="toggleUserShowOwnFace">
                        <span class="slider"></span>
                    </label>
                    <span>{{ $t('favorites.on') }}</span>
                </div>
            </template>
            <template v-else>
                <button class="btn btn--secondary btn--il" @click="handleSidebar({type: 'user-face'})">
                    <i class="icon-fitting-room"></i>
                    <span>{{ $t('upload.own') }}</span>
                </button>
                <button class="btn btn--mercury btn--o" @click="handleSidebar({type: 'models'})">{{ $t('favorites.photo') }}</button>
            </template>
        </template>
    </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/components/sidebar/SidebarLayout';
import ProductCardSimple from '@/components/cards/productCardSimple';

export default {
    props: [],

    components: {
        SidebarLayout,
        ProductCardSimple,
    },

    data() {
        return {
            showOwnFace: false,
        };
    },

    computed: {
        data() {
            return this.$store.getters.getData;
        },

        favorites() {
            return Object.values(this.$store.getters.getGlasses).filter((item) => this.$store.getters.getFavorites.includes(item.slug));
        },

        userHasUploadedImage() {
            return this.$store.getters.getUserHasUploadedImage;
        },

        userShowOwnFace() {
            return this.$store.getters.getUserShowOwnFace;
        },
    },

    methods: {
        toggleUserShowOwnFace() {
            this.$store.dispatch('toggleUserShowOwnFace').then((res) => {
                this.showOwnFace = res;
            });
        },
    },
};
</script>

<template>
    <div class="pupil-size-message btn btn--xs btn--secondary">
        <template v-if="$i18n.locale === 'nl'">
            Gekozen bril niet beschikbaar in uw formaat.
        </template>
        <template v-else-if="$i18n.locale === 'de'">
            Ausgewählte Brille in Ihrer Größe nicht verfügbar.
        </template>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    },
    computed: {},
    methods: {},
};
</script>

<template>
    <div class="card card--product card--simple" :class="{ 'user-face' : showUserFace }">
        <div class="actions-wrapper">
            <div class="action action--btn" @click="removeFromList">
                <i class="icon-heart-filled" v-if="type === 'favorites'"></i>
                <i class="icon-close" v-else></i>
            </div>
        </div>
        <div class="inner">
            <div class="image-wrapper">
                <cld-image :public-id="imagePath" v-if="showUserFace">
                    <cld-transformation gravity="face" width="250" crop="fill"/>
                </cld-image>
                <img :src="showThumb()" alt="" v-else>
            </div>
        </div>
        <div class="card__button-wrapper">
            <button class="btn btn--sm" @click="addToCart" v-if="!isInCart && type ==='favorites'">
                <span>{{ $t('cart.add_short') }}</span>
            </button>
            <button class="btn btn--sm" @click="removeFromCart" v-if="isInCart && type ==='favorites'">
                <span>{{ $t('cart.remove_short') }}</span>
            </button>

            <router-link :to="{ name: 'single', params: { slug: item.slug }}" class="btn btn--sm"
                         @click.native="handleSidebar({ action: 'close' })">
                {{ item.brand.name }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item', 'type'],

    computed: {
        showUserFace() {
            return this.$store.getters.getUserHasUploadedImage && this.$store.getters.getUserShowOwnFace;
        },

        imagePath() {
            return this.$store.getters.getImagePath;
        },

        isInCart() {
            if (this.item) {
                return this.$store.getters.getCart.includes(this.item.slug);
            } else {
                return false;
            }
        },
    },

    methods: {
        addToCart() {
            this.$store.dispatch('addToCart', this.item.slug);
        },

        removeFromCart() {
            this.$store.dispatch('removeFromCart', this.item.slug);
        },

        removeFromList() {
            if (this.type === 'favorites') {
                this.$store.dispatch('removeFromFavorites', this.item.slug);
            } else {
                this.$store.dispatch('removeFromCart', this.item.slug);
            }
        },

        showThumb() {
            if (this.item.images.side2.media) {
                return this.item.images.side2.media;
            }

            return this.item.images.side1.media;
        },
    },
};
</script>

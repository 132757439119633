<template>
    <div class="card card--add">
        <router-link :to="{ name: 'overview' }" class="card__anchor"></router-link>
        <div class="icon-wrapper">
            <i class="icon-plus"></i>
        </div>

        <div class="footer">
            <p class="body--small">
                {{ $t('message.addFrame') }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
  //
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/i18n';
import PQueue from 'p-queue';
import moment from 'moment';

import { api, axiosInstance, fittingRoom } from './api/index';
import gsap from 'gsap';
import Backdrop from '@scripts/component/backdrop';
import Snackbar from '@scripts/component/snackbar';
import axios from 'axios';
import getLightnessOfRGB from '@scripts/component/colorSaturations';

Vue.use(Vuex);

const backdrop = new Backdrop('backdrop');
const snackbar = new Snackbar(undefined, document.querySelector('brilonline-widget'));
const responseArray: object[] = [];

export interface State {
    localStorageKey: string;
    environment: string | undefined;
    loading: boolean;
    scrollPosition: number;
    data: {
        [key: string]: never;
    };
    glasses: {
        [key: string]: any;
    };
    originalGlasses: {
        [key: string]: any;
    };
    models: object[];
    listOpticians: [];
    filteredListOpticians: [];
    singleOptician: boolean,
    mapOpticians: [];
    opticians: {
        [key: string]: never;
    };
    modelFilters: {
        [key: string]: any;
    };
    brands: {
        [key: string]: never;
    };
    colors: {
        [key: string]: string;
    };
    isWebsiteOrigin: boolean;
    showUI: boolean;
    filters: {
        [key: string]: never;
    };
    activeFilters: {
        [key: string]: any;
    };
    mergedActiveFilters: {
        [key: string]: any;
    };
    filteredObjects: object[];
    numberOfActiveFilters: number;
    favorites: number[];
    cart: number[];
    glassesFit: Set<string>;
    userHasUploadedImage: boolean;
    userShowOwnFace: boolean;
    sidebarIsOpen: boolean;
    whichSidebar: string[];
    fittingRoom: {
        [key: string]: any;
        glass: {
            media: string;
            size: number;
            uid: string;
            pupilSize: number|null;
        };
        model: {
            image: string;
            cloud: string;
            gender: string|null;
            pupilSize: number|null;
            concept: string|null;
        };
    };
    share: {
        image: string;
    };
    userInput: object;
    userInfo: {
        [key: string]: any;
        pupilSize: number|null;
    };
    pupilSizeFallbackValue: number;
    opticianSearchInput: string;
    searchInput: string;
    searchOutput: object;
    activeOptician: string;
    paramString: string;
    totalOfPages: number;
    totalGlassesFromApi: number;
    glassesLoaded: boolean,
    order: object,
}

export default new Vuex.Store({
    state: {
        localStorageKey: 'brilonline',
        environment: process.env.NODE_ENV,
        loading: false,
        scrollPosition: 0,
        data: {},
        glasses: {},
        originalGlasses: {},
        models: [],
        listOpticians: [],
        filteredListOpticians: [],
        singleOptician: false,
        mapOpticians: [],
        opticians: {},
        modelFilters: {},
        brands: {},
        colors: {
            primary: '',
            primaryText: '',
            secondary: '',
            secondaryText: '',
        },
        isWebsiteOrigin: false,
        showUI: true,
        filters: {},
        activeFilters: {},
        mergedActiveFilters: {},
        numberOfActiveFilters: 0,
        filteredObjects: [],
        favorites: [],
        cart: [],
        glassesFit: new Set(),
        userHasUploadedImage: false,
        userShowOwnFace: false,
        sidebarIsOpen: false,
        whichSidebar: [],
        fittingRoom: {
            glass: {
                media: '',
                size: 0,
                uid: '',
                pupilSize: null,
            },
            model: {
                image: '',
                cloud: '',
                gender: '',
                pupilSize: null,
                concept: '',
            },
            imageLocation: {
                model: '',
                user: '',
                preview: '',
            },
            selected: '',
        },
        share: {
            image: '',
        },
        userInput: {
            x: 0,
            y: 0,
            rotate: 0,
            scale: 0,
        },
        userInfo: {
            pupilSize: null,
        },
        pupilSizeFallbackValue: 68,
        opticianSearchInput: '',
        searchInput: '',
        searchOutput: {},
        activeOptician: '',
        paramString: '',
        totalOfPages: 0,
        totalGlassesFromApi: 0,
        glassesLoaded: false,
        order: {},
    } as State,

    getters: {
        getEnvironment(state) {
            return state.environment;
        },

        isLoading(state) {
            return state.loading;
        },

        getScrollPosition(state) {
            return state.scrollPosition;
        },

        getData(state) {
            return state.data;
        },

        getColors(state) {
            return state.colors;
        },

        getGlasses(state) {
            return state.glasses;
        },

        getOriginalGlasses(state) {
            return state.originalGlasses;
        },

        getOpticians(state) {
            return state.opticians;
        },

        getModels(state) {
            return state.models;
        },

        getBrands(state) {
            return state.brands;
        },

        getModelFilters(state) {
            return state.modelFilters;
        },

        isWebsiteOrigin(state) {
            return state.isWebsiteOrigin;
        },

        getListOpticians(state) {
            if (state.filteredListOpticians.length > 0) {
                return state.filteredListOpticians;
            }
            if (state.listOpticians.length > 0) {
                return state.listOpticians;
            }
            return state.opticians;
        },

        getMapOpticians(state) {
            if (state.mapOpticians.length > 0) {
                return state.mapOpticians;
            }
            return state.opticians;
        },

        getSingleOpticianView(state) {
            return state.singleOptician;
        },

        getCart(state) {
            return state.cart;
        },

        getFavorites(state) {
            return state.favorites;
        },

        getFavoritesCount(state) {
            return state.favorites.length;
        },

        getCartCount(state) {
            return state.cart.length;
        },

        getUserHasUploadedImage(state) {
            return state.userHasUploadedImage;
        },

        getUserShowOwnFace(state) {
            return state.userShowOwnFace;
        },

        getShareImage(state) {
            return state.share.image;
        },

        getItemById: (state) => (slug: string) => {
            return Object.values(state.originalGlasses).find(item => item.slug === slug);
        },

        getModel(state) {
            return state.fittingRoom.model;
        },

        getFittingRoom(state) {
            return state.fittingRoom;
        },

        getSelectedGlass(state) {
            return state.fittingRoom.glass;
        },

        getImagePath(state) {
            let imagePath = '';

            if (state.fittingRoom.model.gender && state.fittingRoom.imageLocation.model) {
                imagePath = `v5/models/${state.fittingRoom.model.gender}/${state.fittingRoom.imageLocation.model}`;
            }

            if (state.fittingRoom.imageLocation.user !== '') {
                imagePath = `user_uploads/${state.fittingRoom.imageLocation.user}`;
            }

            return imagePath;
        },

        getUserInput(state) {
            const storageString = localStorage.getItem(`${state.localStorageKey}-userInput`);
            if (storageString !== null) {
                state.userInput = JSON.parse(storageString);
                return state.userInput;
            }
            return false;
        },

        getWhichSidebar(state) {
            return state.whichSidebar;
        },

        getShowUI(state) {
            return state.showUI;
        },

        getActiveOptician(state) {
            return state.activeOptician;
        },

        getUserLocation(state) {
            return state.searchOutput;
        },

        getSearchInput(state) {
            return state.searchInput;
        },

        getOpticianSearchInput(state) {
            return state.opticianSearchInput;
        },

        getUserInfo(state) {
            return state.userInfo;
        },

        getLocalStorageKey(state) {
            return state.localStorageKey;
        },

        getFilters(state) {
            return state.filters;
        },

        getActiveFilters(state) {
            return state.activeFilters;
        },

        getMergedActiveFilters(state) {
            return state.mergedActiveFilters;
        },

        getFilteredObjects(state) {
            return state.filteredObjects;
        },

        getNumberOfActiveFilters(state) {
            return state.numberOfActiveFilters;
        },
        getTotalOfPages(state) {
            return state.totalOfPages;
        },
        getTotalGlassesFromApi(state) {
            return state.totalGlassesFromApi;
        },
        getGlassesLoaded(state) {
            return state.glassesLoaded;
        },
        getOrder(state) {
            return state.order;
        },
    },

    mutations: {
        setUserLocation(state, payload) {
            state.searchOutput = payload;
        },

        resetParams() {
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            window.history.replaceState(null, '', newUrl);
        },

        handleList(state, payload) {
            const id = payload.id;
            const listName: string = payload.list;
            const storageString: string | null = localStorage.getItem(`${state.localStorageKey}-${listName}`);
            const stateLocal: any = state;
            // eslint-disable-next-line no-prototype-builtins
            if (!state.hasOwnProperty(listName)) return; // Guard clause
            stateLocal[listName] = [];

            // Use localstorage data to set Vuex data
            if (storageString !== null) {
                stateLocal[listName] = JSON.parse(storageString);
            }

            // Add or remove
            const index = stateLocal[listName].indexOf(id);
            if (index >= 0) {
                stateLocal[listName].splice(index, 1);
            } else {
                stateLocal[listName].push(id);
            }

            localStorage.setItem(`${state.localStorageKey}-${listName}`, JSON.stringify(stateLocal[listName]));
        },

        getLocalStorageData(state, payload: Array<string>) {
            payload.forEach((listName: string) => {
                const storageString: string | null = localStorage.getItem(`${state.localStorageKey}-${listName}`);
                const stateLocal: any = state;
                if (storageString !== null) {
                    stateLocal[listName] = JSON.parse(storageString);
                }
            });
        },

        setScrollPosition(state, payload) {
            state.scrollPosition = payload;
        },

        setUserInfo(state, payload) {
            state.userInfo = payload;
        },

        setPupilSizeUserInfo(state, payload) {
            let payloadValue = payload;
            if (payload === '' || payload === null) {
                payloadValue = state.pupilSizeFallbackValue;
            }
            state.fittingRoom.model.pupilSize = payloadValue;
            state.userInfo.pupilSize = payloadValue;

            const storage = localStorage.getItem(`${state.localStorageKey}-userdata`);
            if (storage) {
                const storageValue = JSON.parse(storage);
                storageValue.pupilSize = state.userInfo.pupilSize;
                localStorage.setItem(`${state.localStorageKey}-userdata`, JSON.stringify(storageValue));
            } else {
                localStorage.setItem(`${state.localStorageKey}-userdata`, JSON.stringify(state.userInfo));
            }
        },

        setFittingRoomModel(state, payload) {
            const { gender, concept, image, cloud } = payload;
            state.fittingRoom.model.cloud = cloud;
            state.fittingRoom.model.concept = concept.slug;
            state.fittingRoom.model.gender = gender.slug;
            state.fittingRoom.model.image = image;
            state.fittingRoom.model.pupilSize = payload.pupil_size;
            state.fittingRoom.imageLocation.model = cloud;
            state.share.image = '';
        },

        setModelFilters(state) {
            const filters = {} as object[];
            Object.values(state.models).forEach((item: any) => {
                if (!('gender' in item) && (!('concept' in item))) {
                    return;
                }

                const { slug, name } = item.gender;

                if (item.concept.slug === 'brilonline') {
                    filters[slug] = name;
                } else if (item.concept.slug !== null) {
                    filters[item.concept.slug] = item.concept.name;
                }
            });

            state.modelFilters = filters;
        },

        changeFilters(state, payload) {
            const removeBrandsBasedOnConcept = () => {
                const isConceptsFilter = payload.parent === 'concepts';
                const hasActiveFilterConcepts = Object.prototype.hasOwnProperty.call(state.activeFilters, 'concepts');
                const hasActiveFilterBrands = Object.prototype.hasOwnProperty.call(state.activeFilters, 'brands');

                if (hasActiveFilterConcepts && hasActiveFilterBrands && isConceptsFilter) {
                    const activeBrands = state.activeFilters.brands;
                    const activeConcepts = state.activeFilters.concepts;
                    const activeConceptsKeys = Object.keys(activeConcepts);

                    Object.entries(activeBrands).forEach(([parentKey]) => {
                        if (Object.prototype.hasOwnProperty.call(activeBrands[parentKey], 'concepts')) {
                            // @ts-ignore
                            const found = activeBrands[parentKey].concepts.some(r => activeConceptsKeys.includes(r));
                            if (!found) {
                                delete state.activeFilters.brands[parentKey];

                                if (Object.keys(state.activeFilters.brands).length === 0) {
                                    delete state.activeFilters[parentKey];
                                }
                            }
                        }
                    });
                }
            };

            const setUrlByFilters = () => {
                // @ts-ignore
                const paramStringArray = [];
                Object.keys(state.activeFilters).forEach(key => {
                    const arr = Object.keys(state.activeFilters[key]);
                    paramStringArray.push(`${key}=${arr.join(',')}`);
                });
                // @ts-ignore
                const paramString = `${paramStringArray.join('&')}`;
                state.paramString = paramString;
                const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${paramString}`;
                window.history.replaceState(null, '', newUrl);
            };

            return new Promise(() => {
                const { parent, filter } = payload;
                const { slug } = filter;

                // Also create a merged list for the active filters component
                const mergedFilter = {
                    parent,
                    name: filter.name,
                    slug,
                };

                // Create activeFilter parent if not existing
                if (!state.activeFilters[parent]) {
                    state.activeFilters[parent] = {};
                }

                // Delete filter from parent if already existing
                if (state.activeFilters[parent][slug]) {
                    delete state.activeFilters[parent][slug];

                    if (Object.keys(state.activeFilters[parent]).length === 0) {
                        delete state.activeFilters[parent];
                    }
                } else {
                    // Push filter to parent
                    state.activeFilters[parent][slug] = filter;
                }

                if (state.mergedActiveFilters[mergedFilter.slug]) {
                    delete state.mergedActiveFilters[mergedFilter.slug];
                } else {
                    state.mergedActiveFilters[mergedFilter.slug] = mergedFilter;
                }

                state.numberOfActiveFilters = Object.keys(state.mergedActiveFilters).length;

                removeBrandsBasedOnConcept();
                setUrlByFilters();
                localStorage.setItem(`${state.localStorageKey}-activefilters`, JSON.stringify(state.activeFilters));
            });
        },

        filterObjectsMutation(state) {
            return new Promise((resolve) => {
                const asArray: object[] = [];

                Object.keys(state.glasses).forEach((key) => {
                    asArray.push(state.glasses[key]);
                });

                state.filteredObjects = asArray.filter((item: { [key: string]: any }) => {
                    if (Object.keys(state.activeFilters).length === 0) {
                        return true;
                    }
                    let counter = 0;
                    let returnValue = false;

                    Object.keys(state.activeFilters).forEach((parent) => {
                        if (item.filters[parent]) {
                            item.filters[parent].some((filter: { [key: string]: any }) => {
                                returnValue = typeof state.activeFilters[parent][filter.slug] !== 'undefined';
                                if (returnValue) {
                                    counter += 1;
                                }
                                return returnValue;
                            });
                        } else {
                            counter += 1;
                            returnValue = true;
                        }
                    });

                    return Object.keys(state.activeFilters).length > 0 && counter === Object.keys(state.activeFilters).length && returnValue;
                });

                if (state.userInfo.pupilSize) {
                    state.filteredObjects = state.filteredObjects.filter((item: { [key: string]: any }) => item.sizes.pupil === state.userInfo.pupilSize);
                }

                resolve('Mutate objects by filter!');
            });
        },

        async placeOrder(state) {
            const orderData = state.userInfo;
            orderData.date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
            orderData.glasses = state.cart;
            orderData.optician = state.activeOptician;
            // @ts-ignore
            api.put(state.order.href, orderData)
                .then(() => {
                    window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}#/order/complete`;
                    state.cart = [];
                    state.activeOptician = '';
                    localStorage.removeItem(`${state.localStorageKey}-cart`);
                    localStorage.removeItem(`${state.localStorageKey}-activeOptician`);
                    localStorage.removeItem(`${state.localStorageKey}-userdata`);
                }).catch((err) => {
                throw new Error(err);
            });
        },

        setFittingRoomSelected(state, payload) {
            state.fittingRoom.selected = payload;
        },
    },

    actions: {
        postGlassesFit({ state }, payload) {
            if (!(state.glassesFit.has(payload))) {
                state.glassesFit.add(payload);
                axiosInstance.get(`${fittingRoom}/glasses/count/${payload}.json`).then();
            }
        },

        updateScrollPosition({ commit }, payload) {
            commit('setScrollPosition', payload);
        },

        updateFittingRoomSelected({ commit }, payload) {
            commit('setFittingRoomSelected', payload);
        },

        fetchBrands({ state }, payload: any) {
            axiosInstance.get(payload.href).then((resp) => {
                state.brands = resp.data.brands;
            }).catch((err) => {
                throw new Error(err as string);
            });
        },

        mutateGlassesByPupilSize({ state, dispatch }) {
            const clonedGlassesObject: object[] = JSON.parse(JSON.stringify(state.originalGlasses));
            const arrayGlassByPupilSize = [];

            for (let i = 0; i < clonedGlassesObject.length; i += 1) {
                const item: {
                    [key: string] : any;
                } = clonedGlassesObject[i];

                if (state.userInfo.pupilSize) {
                    if (item.sizes.pupil === state.userInfo.pupilSize) {
                        arrayGlassByPupilSize.push(item);
                    }
                } else if (
                    state.fittingRoom.model.pupilSize === item.sizes.pupil &&
                    (['unisex', state.fittingRoom.model.gender].includes(item.gender.gender) &&
                    (item.concepts[0] && item.concepts[0].slug === state.fittingRoom.model.concept))
                ) {
                    arrayGlassByPupilSize.push(item);
                }
            }

            state.glasses = arrayGlassByPupilSize;
            dispatch('filterObjectsAsync');
        },

        async fetchGlasses({ state, dispatch }, payload: any) {
            const fetchUrl = payload.href;
            const queue = new PQueue({
                concurrency: 1,
            });

            await axiosInstance.get(fetchUrl).then((resp) => {
                state.totalOfPages = resp.data.pagination.pages;
                state.totalGlassesFromApi = resp.data.pagination.total;

                responseArray.push(resp.data.glasses);
                state.originalGlasses = responseArray.flat();
            }).then(() => {
                for (let i = 1; i < state.totalOfPages; i += 1) {
                    queue.add(() => axiosInstance.get(`${fetchUrl}?page=${i + 1}`)
                        .then((response) => {
                            responseArray.push(response.data.glasses);
                        })
                        .catch((err) => {
                            throw new Error(err);
                        }));
                }

                const completeTask = () => {
                    state.originalGlasses = responseArray.flat();

                    if (this.getters.getImagePath === null || this.getters.getImagePath.trim() === '') {
                        state.glasses = state.originalGlasses;
                        dispatch('filterObjectsAsync');
                    } else {
                        dispatch('mutateGlassesByPupilSize');
                    }
                };

                if (queue.size === 0) {
                    completeTask();
                } else {
                    queue.on('completed', () => {
                        completeTask();
                    });
                }

                queue.onIdle().then(() => {
                    state.glassesLoaded = true;
                });
            }).catch((err) => {
                throw new Error(err as string);
            });
        },

        async fetchFilters({ state, dispatch }, payload) {
            const filters = await axiosInstance.get(payload.href);
            state.filters = filters.data.filters;
            dispatch('changeActiveFilters');
        },

        fetchModels({ state, commit }, payload: any) {
            axiosInstance.get(payload.href).then((resp) => {
                const modelsDataRes = resp.data.models;
                const modelsDataPromise = new Promise((resolve) => {
                    modelsDataRes.forEach((model: { cloud: string; gender: { slug: string; }; }, index: number, array: []) => {
                        if (model.cloud !== null) {
                            axios({
                                method: 'GET',
                                url: `https://res.cloudinary.com/${process.env.VUE_APP_CLD_NAME}/image/upload/models/${model.gender.slug}/${model.cloud}`,
                                validateStatus: () => true,
                            }).then((resp) => {
                                if (resp.status === 200) {
                                    state.models.push(model);
                                }

                                if (index === array.length - 1) {
                                    resolve('Models finished checking Cloudinary!');
                                }
                            });
                        }
                    });
                });

                modelsDataPromise.then(() => {
                    commit('setModelFilters');
                });
            }).catch((err) => {
                throw new Error(err as string);
            });
        },

        async fetchCreateOrderData({ state }, payload: any) {
            state.order = payload;
        },

        async fetchOpticians({ state }, payload: any) {
            const opticians = await axiosInstance.get(payload.href);
            state.opticians = opticians.data.opticians;
            state.singleOptician = state.opticians.length === 1;
        },

        loadData({ state, commit, dispatch }) {
            state.loading = true;

            return api.fetch()
                .then((response: { data: any }) => {
                    const entry = response.data;
                    const { room } = entry;

                    if (room.language) {
                        i18n.locale = String(new Intl.Locale(room.language.replace('_', '-')).language);
                    }

                    state.data = room;
                    // Set global colors
                    state.colors.primary = room.layout.color_1.background;
                    state.colors.primaryText = room.layout.color_1.foreground;
                    state.colors.secondary = room.layout.color_2.background;
                    state.colors.secondaryText = room.layout.color_2.foreground;
                    document.documentElement.style.setProperty('--primary-color', state.colors.primary);
                    document.documentElement.style.setProperty('--primary-color-text', state.colors.primaryText);
                    document.documentElement.style.setProperty('--secondary-color', state.colors.secondary);
                    document.documentElement.style.setProperty('--secondary-color-text', state.colors.secondaryText);

                    if (state.colors.secondary === '#000000' || state.colors.secondary === '#000') {
                        document.documentElement.style.setProperty('--button-color', state.colors.primary);
                    }

                    if ((getLightnessOfRGB(state.colors.secondary)) < 0.1) {
                        document.documentElement.style.setProperty('--button-color-label', '#ffffff');
                    } else {
                        document.documentElement.style.setProperty('--button-color-label', '#000000');
                    }

                    dispatch('fetchFilters', entry._links.filters);
                    dispatch('fetchModels', entry._links.models);
                    dispatch('fetchGlasses', entry._links.glasses);
                    dispatch('fetchBrands', entry._links.brands);
                    dispatch('fetchOpticians', entry._links.opticians);
                    dispatch('fetchCreateOrderData', entry._links.order);

                    commit('getLocalStorageData', [
                        'favorites',
                        'cart',
                    ]);

                    // Set User selected images (model and glasses) from localStorage
                    const storageString = localStorage.getItem(`${state.localStorageKey}-fittingRoom`);
                    if (storageString !== null) {
                        const parsedStorageString = JSON.parse(storageString);
                        state.fittingRoom = parsedStorageString;
                        state.userHasUploadedImage = parsedStorageString.imageLocation.user !== '';
                    }
                })
                .catch((err) => {
                    throw new Error(err);
                });
        },

        handleData({ state }) {
            state.loading = false;
        },

        setShareImage({ state }, payload) {
            state.share.image = payload;
        },

        addToFavorites({ state, commit }, id: number) {
            return new Promise((resolve, reject) => {
                const message = {
                    success: String(i18n.t('snackbars.wishlist_add')),
                    failure: String(i18n.t('snackbars.wishlist_exist')),
                };

                if ((state.favorites as number[]).includes(id)) {
                    snackbar.connected(message.failure);
                    reject(new Error(message.failure));
                    return;
                }
                commit('handleList', {
                    id: id,
                    list: 'favorites',
                });
                snackbar.connected(message.success);
                resolve(message.success);
            });
        },

        removeFromFavorites({ state, commit }, id: number) {
            return new Promise((resolve, reject) => {
                const message = {
                    success: String(i18n.t('snackbars.wishlist_remove')),
                    failure: String(i18n.t('snackbars.wishlist_non_exist')),
                };

                if (!(state.favorites as number[]).includes(id)) {
                    snackbar.connected(message.failure);
                    reject(new Error(message.failure));
                    return;
                }
                commit('handleList', {
                    id: id,
                    list: 'favorites',
                });
                snackbar.connected(message.success);
                resolve(message.success);
            });
        },

        addToCart({ state, commit }, id: number) {
            return new Promise((resolve, reject) => {
                const message = {
                    success: String(i18n.t('snackbars.reservation_add')),
                    failure: String(i18n.t('snackbars.reservation_exist')),
                };

                if ((state.cart as number[]).includes(id)) {
                    snackbar.connected(message.failure);
                    reject(new Error(message.failure));
                    return;
                }
                commit('handleList', {
                    id: id,
                    list: 'cart',
                });
                snackbar.connected(message.success);
                resolve(message.success);
            });
        },

        removeFromCart({ state, commit }, id: number) {
            return new Promise((resolve, reject) => {
                const message = {
                    success: String(i18n.t('snackbars.reservation_remove')),
                    failure: String(i18n.t('snackbars.reservation_not_added')),
                };

                if (!(state.cart as number[]).includes(id)) {
                    snackbar.connected(message.failure);
                    reject(new Error(message.failure));
                    return;
                }
                commit('handleList', {
                    id: id,
                    list: 'cart',
                });
                snackbar.connected(message.success);
                resolve(message.success);
            });
        },

        handleSidebar({ state }, payload) {
            const { type, action } = payload;
            let sidebars = document.querySelectorAll('[data-sidebar]');
            if (type) {
                sidebars = document.querySelectorAll(`[data-sidebar="${type}"]`);
            }
            const duration = 0.2;
            const ease = 'power2.inOut';

            const closeSidebar = (sidebar: HTMLElement) => {
                gsap.to(
                    sidebar,
                    {
                        x: '100%',
                        duration,
                        ease,
                        onComplete: () => {
                            document.documentElement.classList.remove('modal-is-open');
                        },
                    },
                );
                state.whichSidebar.splice((state.whichSidebar as string[]).indexOf(type), 1);

                if (state.whichSidebar.length === 0) {
                    backdrop.disconnected(false);
                    state.sidebarIsOpen = false;
                }
            };

            const openSidebar = (sidebar: HTMLElement) => {
                gsap.to(
                    sidebar,
                    {
                        x: 0,
                        duration,
                        ease,
                        onStart: () => {
                            document.documentElement.classList.add('modal-is-open');
                        },
                    },
                );
                backdrop.connected(true);
                state.sidebarIsOpen = true;
                (state.whichSidebar as string[]).push(type);
            };

            if (action) {
                if (action === 'open') {
                    Array.from(sidebars).forEach((sidebar) => {
                        openSidebar(sidebar as HTMLElement);
                    });
                }
                if (action === 'close') {
                    Array.from(sidebars).forEach((sidebar) => {
                        closeSidebar(sidebar as HTMLElement);
                    });
                }
                return;
            }

            if (state.sidebarIsOpen) {
                Array.from(sidebars).forEach((sidebar) => {
                    closeSidebar(sidebar as HTMLElement);
                });
            } else {
                Array.from(sidebars).forEach((sidebar) => {
                    openSidebar(sidebar as HTMLElement);
                });
            }
        },

        changeFittingRoomGlasses({ state }, payload) {
            state.fittingRoom.glass.media = payload.media;
            state.fittingRoom.glass.size = payload.size;
            state.fittingRoom.glass.uid = payload.uid;
            state.fittingRoom.glass.pupilSize = payload.pupilSize;
            state.share.image = '';
        },

        changeFittingRoomModel({ commit, dispatch }, payload) {
            commit('setFittingRoomModel', payload);
            dispatch('mutateGlassesByPupilSize');
        },

        changeFittingRoomPreview({ state }, payload) {
            state.fittingRoom.imageLocation.preview = payload;
        },

        changeFittingRoomView({ state }, payload) {
            state.fittingRoom.selected = payload;
        },

        updateImageLocationUser({ state, dispatch }, payload) {
            state.userHasUploadedImage = true;
            state.fittingRoom.selected = 'user';
            state.fittingRoom.imageLocation.user = payload;
            // Reset values
            state.fittingRoom.model.concept = null;
            state.fittingRoom.model.gender = null;
            state.userInfo.pupilSize = state.pupilSizeFallbackValue;
            // Dispatch event
            dispatch('mutateGlassesByPupilSize');
        },

        updateUserPupilSize({ dispatch }) {
            dispatch('mutateGlassesByPupilSize');
        },

        saveUserInput({ state }, payload) {
            const { key, data } = payload;
            // @ts-ignore
            state[key] = payload;
            localStorage.setItem(`${state.localStorageKey}-${key}`, JSON.stringify(data));
        },

        resetLocalStorageData({ state }) {
            return new Promise((resolve) => {
                Object.keys(localStorage).forEach((key) => {
                    if (key.includes(state.localStorageKey)) {
                        localStorage.removeItem(key);
                    }
                });

                Object.keys(state.userInput).forEach((key) => {
                    // @ts-ignore
                    state.userInput[key] = 0;
                });

                resolve('Successfully cleared localStorage');
                location.reload();
            });
        },

        showUI({ state }, payload) {
            state.showUI = payload;
        },

        setWebsiteOrigin({ state }, payload) {
            state.isWebsiteOrigin = payload;
        },

        setActiveOptician({ state }, payload) {
            state.activeOptician = payload;
            localStorage.setItem(`${state.localStorageKey}-activeOptician`, JSON.stringify(state.activeOptician));
        },

        searchForOptician({ state }, payload) {
            if (payload !== undefined) {
                state.searchInput = payload.toLowerCase();
            }
            state.opticianSearchInput = payload;
            if (state.opticianSearchInput !== '') {
                localStorage.setItem(`${state.localStorageKey}-opticianSearchInput`, JSON.stringify(state.opticianSearchInput));
            } else {
                localStorage.removeItem(`${state.localStorageKey}-opticianSearchInput`);
            }
        },

        setListOpticians({ state }, payload) {
            state.listOpticians = payload;
        },

        getLocalStorageDataForOpticians({ state }) {
            const activeOpticianStorageString: string | null = localStorage.getItem(`${state.localStorageKey}-activeOptician`);
            if (activeOpticianStorageString !== null) {
                state.activeOptician = JSON.parse(activeOpticianStorageString);
            }
            const opticianSearchInputStorageString: string | null = localStorage.getItem(`${state.localStorageKey}-opticianSearchInput`);
            if (opticianSearchInputStorageString !== null) {
                state.searchInput = JSON.parse(opticianSearchInputStorageString);
                state.opticianSearchInput = state.searchInput;
            }
        },

        toggleUserShowOwnFace({ state }, payload) {
            return new Promise((resolve) => {
                if (payload) {
                    state.userShowOwnFace = payload;
                } else {
                    state.userShowOwnFace = !state.userShowOwnFace;
                }
                resolve(state.userShowOwnFace);
            });
        },

        isFilterActive({ state }, payload) {
            const { parent, filter } = payload;
            // @ts-ignore
            return typeof state.activeFilters[parent] !== 'undefined' && typeof state.activeFilters[parent][filter] !== 'undefined';
        },

        changeActiveFilters({ commit, state }) {
            return new Promise((resolve) => {
                const queryString = window.location.search.replace('?', '');
                if (queryString) {
                    const decodeUrl = decodeURIComponent(queryString);
                    const paramGroups = decodeUrl.split('&');
                    paramGroups.forEach((item) => {
                        const regex = item.split('=');
                        const filterType = regex[0];
                        const itemsString = regex[1];
                        const items = itemsString.split(',');
                        items.forEach((single) => {
                            // eslint-disable-next-line
                            const filterName = state.filters?.[filterType]?.['filters']?.[single]?.['name'];
                            if (filterName !== undefined) {
                                // eslint-disable-next-line
                                const parent = filterType;
                                const filter = {
                                    name: filterName,
                                    slug: single,
                                };
                                if (filter.name && filter.slug) {
                                    commit('changeFilters', { parent, filter });
                                }
                            }
                        });
                    });
                } else {
                    const activeFiltersString = localStorage.getItem(`${state.localStorageKey}-activefilters`);
                    if (activeFiltersString !== null) {
                        state.activeFilters = JSON.parse(activeFiltersString);
                    }
                }
                state.numberOfActiveFilters = Object.keys(state.activeFilters).length;
                resolve(state.activeFilters);
            });
        },

        deleteAllFilters({ state }) {
            return new Promise(() => {
                // @ts-ignore
                state.filteredObjects = state.glasses;
                state.activeFilters = {};
                state.numberOfActiveFilters = 0;
                localStorage.removeItem(`${state.localStorageKey}-activefilters`);
                this.commit('resetParams');
            });
        },

        async deleteAllFiltersAsync({ commit, dispatch }) {
            commit('filterObjectsMutation', await dispatch('deleteAllFilters'));
        },

        async filterObjectsAsync({ commit }) {
            commit('filterObjectsMutation');
        },

        async changeFiltersAsync({ commit }, payload) {
            commit('filterObjectsMutation', await commit('changeFilters', payload));
        },

        async placeOrderAsync({ commit }) {
            commit('placeOrder');
        },
    },
});

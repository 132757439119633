<template>
    <SidebarLayout type="user-face">
        <template #header>
            <button type="button" class="btn btn--md btn--il btn--back btn--wild-sand" v-show="step !== 'intro'"
                    @click="setStep('intro')">
                <i class="icon-chevron-left"></i><span>{{ $t('message.back') }}</span>
            </button>
        </template>

        <StepIntro v-if="step === 'intro'"></StepIntro>
        <StepUpload v-if="step === 'upload'" v-bind.sync="user"></StepUpload>
        <StepCamera v-if="step === 'camera'" :mediaStream.sync="mediaStream" :constraints="constraints" v-on:updateFacingMode="setFacingMode" v-bind.sync="user"></StepCamera>

        <template #footer>
            <template v-if="step === 'intro'">
                <button class="btn btn--secondary" :disabled="!user.cameraEnabled" ref="use-camera"
                        @click="setStep('camera')">
                    {{ $t('message.use_camera') }}
                </button>
            </template>
            <template v-if="step === 'upload' || step === 'camera'">
                <button class="btn btn--secondary" @click="setUserData" v-show="user.data">
                    {{ $t('apply.photo') }}
                </button>
                <button class="btn btn--secondary" disabled v-show="!user.preview">
                    {{ $t('message.continue') }}
                </button>
                <button class="btn btn--o btn--il btn--mercury" :disabled="!user.cameraEnabled" ref="use-camera"
                        @click="setStep('camera')" v-if="step === 'upload'">
                    <i class="icon-fitting-room"></i>
                    {{ $t('photo.build') }}
                </button>
                <button class="btn btn--primary" :disabled="!user.data" @click="handleSidebar({type: 'user-face', action: 'close'}); handleSidebar({type: 'pupil-distance'});">
                    {{ $t('message.pupil_distance') }}
                </button>
            </template>
        </template>
    </SidebarLayout>
</template>

<script>
import { axiosInstance, fittingRoom } from '@/store/api';
import SidebarLayout from './SidebarLayout';
import StepIntro from './step/StepIntro';
import StepUpload from './step/StepUpload';
import StepCamera from './step/StepCamera';

export default {
    components: {
        SidebarLayout,
        StepIntro,
        StepUpload,
        StepCamera,
    },

    data() {
        return {
            step: 'intro',
            user: {
                cameraEnabled: null,
                data: null,
                preview: null,
            },
            mediaStream: null,
            constraints: {
                audio: false,
                video: {
                    facingMode: 'environment',
                },
            },
        };
    },

    computed: {
        whichSidebar() {
            return this.$store.getters.getWhichSidebar;
        },

        fittingRoom() {
            return this.$store.getters.getFittingRoom;
        },

        imageLocation() {
            return this.fittingRoom.imageLocation;
        },
    },

    methods: {
        setStep(type) {
            this.step = type;
        },

        async setUserData(ev) {
            try {
                const button = ev.currentTarget;
                const file = new Blob([this.user.data]);
                const formData = new FormData();
                formData.append('image', file, file.fileName);

                button.classList.add('disabled');

                await axiosInstance({
                    method: 'post',
                    url: `${process.env.VUE_APP_ORDERS_BASEURL}/api/rooms/${fittingRoom}/upload.json`,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: formData,
                })
                    .then((resp) => {
                        this.$store.dispatch('updateImageLocationUser', resp.data.url);
                        this.$store.dispatch('handleSidebar', {
                            type: 'user-face',
                            action: 'close',
                        });
                        button.classList.remove('disabled');
                    })
                    .catch((err) => {
                        throw new Error(err);
                    });
            } catch (err) {
                throw new Error(err);
            }
        },

        async getMediaStream() {
            try {
                this.mediaStream = await navigator.mediaDevices.getUserMedia(this.constraints);
                if (this.mediaStream !== null && this.mediaStream.active) {
                    this.user.cameraEnabled = true;
                }
            } catch (err) {
                this.user.cameraEnabled = false;
                throw new Error(err.message);
            }
        },

        setFacingMode(value) {
            this.constraints.video.facingMode = value;
        },
    },

    watch: {
        async whichSidebar(type) {
            if (type.includes('user-face')) {
                await this.getMediaStream();
            }
        },
    },
};
</script>

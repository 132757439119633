import { gsap } from 'gsap';

class Snackbar {
    private readonly appClassName: string;
    private app: HTMLElement | Element | undefined;
    private readonly container: HTMLElement | Element;
    private visible: boolean;

    constructor(setClass = 'snackbar', container?:HTMLElement|Element|null) {
        this.appClassName = setClass;
        this.container = container ?? document.body;
        this.visible = false;
    }

    connected(message?: string) {
        this.setDOMElement();

        if (this.app) {
            if (typeof message === 'string') {
                this.app.innerHTML = message;
            }
        }
        this.visible = !this.visible;
        this.animating();

        setTimeout(() => {
            this.disconnected();
        }, 2500);
    }

    disconnected() {
        this.visible = !this.visible;
        this.animating();
    }

    getDOMElement = () => document.querySelector(`.${this.appClassName}`)

    setDOMElement() {
        this.app = this.getDOMElement() || this.createElement();
    }

    createElement() {
        const el = document.createElement('div');
        el.classList.add(this.appClassName);
        this.container.append(el);

        return el;
    }

    animating() {
        if (!this.app) {
            return false;
        }

        return gsap.to(this.app, {
            y: 0,
            yPercent: this.visible ? 0 : 200,
            duration: 0.25,
            ease: 'power1.easeOut',
            onComplete: () => {
                if (!this.visible && this.app) {
                    this.app.innerHTML = '';
                }
            },
        });
    }
}

export default Snackbar;

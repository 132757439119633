<template>
    <SidebarLayout type="models">
        <template #title>{{ $t('models.title') }}</template>
        <div class="row">
            <div class="col-6" v-for="(item, index) in filteredModels" :key="index" @click="selectModel(item)">
                <div class="image-wrapper">
                    <img :src="item.image" alt="">
                </div>
                <button class="btn btn--sm" :class="isSelected(index) ? 'btn--primary' : 'btn--milk'">
                    <span v-if="isSelected(index)">{{ $t('models.selected') }}</span>
                    <span v-else>{{ $t('models.select') }}</span>
                </button>
            </div>
        </div>

        <template #footer>
            <button class="btn btn--secondary" v-show="isSelected" @click="handleSidebar({type: 'models', action: 'close'})">{{ $t('models.button') }}</button>
        </template>
    </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/components/sidebar/SidebarLayout';

export default {
    props: [],

    components: {
        SidebarLayout,
    },

    data() {
        return {
            activeFilters: [],
        };
    },

    computed: {
        models() {
            return this.$store.getters.getModels;
        },

        modelFilters() {
            return this.$store.getters.getModelFilters;
        },

        filteredModels() {
            if (this.activeFilters.length > 0) {
                return this.models.filter((item) => {
                    let returnValue;

                    if (this.activeFilters.length > 1) {
                        returnValue = this.activeFilters.includes(item.concept.slug) && this.activeFilters.includes(item.gender.slug);
                    } else {
                        returnValue = this.activeFilters.includes(item.concept.slug) || this.activeFilters.includes(item.gender.slug);
                    }

                    return returnValue;
                });
            }
            return this.models;
        },

        selectedModel() {
            return this.$store.getters.getModel;
        },
    },

    methods: {
        toggleFilter(slug) {
            if (!this.activeFilters.includes(slug)) {
                this.activeFilters.push(slug);
            } else {
                this.activeFilters.splice(this.activeFilters.indexOf(slug), 1);
            }
        },

        isActive(slug) {
            return this.activeFilters.includes(slug);
        },

        selectModel(item) {
            this.$store.dispatch('changeFittingRoomModel', item);
            this.$store.dispatch('updateFittingRoomSelected', 'model');
        },

        isSelected(index) {
            return index === this.filteredModels.findIndex((model) => model.image === this.selectedModel.image);
        },
    },
};
</script>

<template>
    <div class="step step--upload">
        <div class="step__content">
            <h4>{{ $t('upload.step_upload') }}</h4>
        </div>
        <div class="upload-wrapper" v-show="dragAndDropCapable">
            <form ref="fileForm" class="working-canvas">
                <input type="file" name="upload-file" ref="inputFile" @change="uploadFile" accept="image/png, image/jpeg">
                <div class="drag-drop-file" @drop="dropFile" @dragover="addClass" @dragleave="removeClass"
                     ref="uploadContainer">
                    <img :src="preview" class="file-preview" alt="" v-if="preview"/>
                    <div class="drag-drop-file__content" v-else>
                        <p>{{ $t('photo.drag_drop') }}</p>
                        <button type="button" class="btn btn--il btn--secondary btn--sm" @click="chooseFile">
                            <i class="icon-upload"></i>
                            {{ $t('photo.choose_from_device') }}
                        </button>
                    </div>
                </div>
            </form>
            <div class="actions" v-if="preview">
                <p>{{ $t('photo.preview') }}</p>
                <button type="button" class="btn btn--o btn--sm btn--mercury" @click="chooseFile">
                    {{ $t('upload.new_photo') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepUpload',

    props: [
        'data',
        'preview',
    ],

    data() {
        return {
            isComplete: false,
            isLoading: false,
            dragAndDropCapable: false,
            container: null,
        };
    },

    mounted() {
        this.container = this.$refs.uploadContainer;
        this.dragAndDropCapable = this.determineDragAndDropCapable();

        if (this.dragAndDropCapable) {
            ['dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
                this.$refs.fileForm.addEventListener(evt, (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                });
            });
        }
    },

    methods: {
        determineDragAndDropCapable() {
            return (('draggable' in this.container) || ('ondragstart' in this.container && 'ondrop' in this.container)) && 'FormData' in window && 'FileReader' in window;
        },

        removeClass(evt) {
            evt.target.classList.remove('user-is-dropping');
        },

        addClass(evt) {
            evt.target.classList.add('user-is-dropping');
        },

        uploadFile() {
            const fileInput = this.$refs.inputFile;
            const imgFile = fileInput.files;

            if ((imgFile && imgFile[0]) && (/\.(jpe?g|png)$/i.test(imgFile[0].name))) {
                const reader = new FileReader();

                reader.addEventListener('load', (e) => {
                    this.$emit('update:preview', e.target.result);
                    this.$emit('update:data', imgFile[0]);
                });

                reader.readAsDataURL(imgFile[0]);
            }
        },

        chooseFile() {
            this.$refs.inputFile.click();
        },

        dropFile(evt) {
            this.removeClass(evt);

            this.$refs.inputFile = evt.dataTransfer;

            this.uploadFile();
        },
    },
};
</script>

import Vue from 'vue';

export default Vue.mixin({
    methods: {
        handleSidebar({ type, action = 'open' }: { type?: string; action?: string; }) {
            this.$store.dispatch('handleSidebar', {
                type: type,
                action: action,
            });
        },
    },
});

import { gsap } from 'gsap';

class Backdrop {
    private readonly appClassName: string;
    private app: HTMLElement | Element | undefined;
    private visible: boolean;

    constructor(setClass: string) {
        this.appClassName = setClass;
        this.visible = false;
    }

    connected(visible: boolean) {
        this.setDOMElement();
        if (visible) {
            this.visible = !visible;
        } else {
            this.visible = false;
        }
        this.animating();
    }

    disconnected(visible: boolean) {
        if (visible) {
            this.visible = !visible;
        } else {
            this.visible = true;
        }
        this.animating();
    }

    getDOMElement = () => document.querySelector(`.${this.appClassName}`)

    setDOMElement() {
        this.app = this.getDOMElement() || this.createElement();
    }

    createElement() {
        const el = document.createElement('div');
        el.classList.add(this.appClassName);
        document.body.append(el);

        return el;
    }

    animating() {
        if (!this.app) {
            return false;
        }

        return gsap.to(this.app, {
            autoAlpha: this.visible ? 0 : 1,
            duration: 0.25,
            ease: 'linear',
        });
    }
}

export default Backdrop;

<template>
    <div class="card card--product card--skeleton">
        <div class="inner">
            <img
                src=""
                alt=""
            >
        </div>
        <div class="card__button-wrapper">
            <div />
            <div />
        </div>
    </div>
</template>

<script>
export default {};
</script>

<template>
    <div>
        <DefaultLayout v-if="!loading && cookiesEnabled"></DefaultLayout>
        <CookieLayoutMessage v-if="!cookiesEnabled"></CookieLayoutMessage>
    </div>
</template>

<style lang="scss">
//
</style>

<script>
import { singleView } from '@/store/api';
import DefaultLayout from '@/views/Layouts/DefaultLayout';
import CookieLayoutMessage from '@/views/Layouts/CookieLayoutMessage';

export default {
    components: {
        DefaultLayout,
        CookieLayoutMessage,
    },

    computed: {
        loading() {
            return this.$store.getters.isLoading;
        },

        cookiesEnabled() {
            return navigator.cookieEnabled;
        },
    },

    created() {
        this.getSingleView();
        this.$store.dispatch('loadData').then(() => {
            this.$store.dispatch('handleData');
        });
    },

    methods: {
        getSingleView() {
            if (singleView) {
                if (this.$router.currentRoute.name !== 'single') {
                    this.$router.push({
                        path: `/${singleView}`,
                    });
                }
            }
        },
    },
};
</script>

<template>
    <SidebarLayout type="pupil-distance">
        <template #title>
            <template v-if="$i18n.locale === 'nl'">
                Meet je pupilafstand
            </template>
            <template v-else-if="$i18n.locale === 'de'">
                Messen Sie Ihre Pupillendistanz
            </template>
        </template>

        <p v-if="$i18n.locale === 'nl'">Dit is de afstand tussen jouw pupillen. Deze maat is belangrijk om te beoordelen of de bril goed staat. Zonder een juiste pupilmaat oogt de bril misschien te groot of te klein op jouw gezicht.</p>
        <p v-else-if="$i18n.locale === 'de'">Dies ist der Abstand zwischen Ihren Pupillen. Diese Größe ist wichtig, um zu beurteilen, ob die Brille richtig sitzt. Ohne die richtige Pupillengröße kann die Brille auf Ihrem Gesicht zu groß oder zu klein aussehen.</p>

        <div class="input-pupil-distance">
            <label for="pupil-distance">
                <template v-if="$i18n.locale === 'nl'">
                    Mijn pupilafstand is
                </template>
                <template v-else-if="$i18n.locale === 'de'">
                    Meine Pupillendistanz ist
                </template>
            </label>
            <div class="input-group">
                <input type="number" id="pupil-distance" v-model.number="pupilDistance">
                <div class="input-group-append">
                    <span>mm</span>
                </div>
            </div>
        </div>

        <div class="sidebar--layout__divider"></div>

        <h5>
            <template v-if="$i18n.locale === 'nl'">
                Vragen
            </template>
            <template v-else-if="$i18n.locale === 'de'">
                Fragen
            </template>
        </h5>

        <div class="accordion-holder">
            <div class="accordion__item">
                <div class="accordion__term">
                    <div>
                        <p v-if="$i18n.locale === 'nl'">Wat is de gemiddelde pupilafstand?</p>
                        <p v-else-if="$i18n.locale === 'de'">Wie groß ist der durchschnittliche Pupillenabstand?</p>
                    </div>
                </div>
                <div class="accordion__body collapse">
                    <div class="accordion__description">
                        <table>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <strong v-if="$i18n.locale === 'nl'">Man</strong>
                                        <strong v-else-if="$i18n.locale === 'de'">Mann</strong>
                                    </td>
                                    <td>
                                        <strong v-if="$i18n.locale === 'nl'">Vrouw</strong>
                                        <strong v-else-if="$i18n.locale === 'de'">Frau</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <template v-if="$i18n.locale === 'nl'">
                                            Volwassen
                                        </template>
                                        <template v-else-if="$i18n.locale === 'de'">
                                            Erwachsene
                                        </template>
                                    </td>
                                    <td>68 mm</td>
                                    <td>63 mm</td>
                                </tr>
                                <tr>
                                    <td>
                                        <template v-if="$i18n.locale === 'nl'">
                                            Tiener
                                        </template>
                                        <template v-else-if="$i18n.locale === 'de'">
                                            Teenager
                                        </template>
                                    </td>
                                    <td>63 mm</td>
                                    <td>60 mm</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="accordion__item">
                <div class="accordion__term">
                    <div>
                        <p v-if="$i18n.locale === 'nl'">Hoe meet je de pupilafstand?</p>
                        <p v-else-if="$i18n.locale === 'de'">Wie misst man die Pupillendistanz?</p>
                    </div>
                </div>
                <div class="accordion__body collapse">
                    <div class="accordion__description">
                        <template v-if="$i18n.locale === 'nl'">
                            <p>Meet de pupilafstand met een liniaal. Zie voorbeeld foto. Je kunt dit het beste een ander laten doen.</p>
                            <p>Kijk recht voor je uit. Meet vanaf het midden van je pupillen.</p>
                            <p><a href="https://paskamer.brilonline.com/static/liniaal.pdf" target="_blank">Print liniaal</a> (Let op: zet de printer wel op 100% afdrukformaat)</p>
                        </template>
                        <template v-else-if="$i18n.locale === 'de'">
                            <p>Messen Sie den Pupillenabstand mit einem Lineal. Siehe Beispielfoto. Das überlässt man am besten jemand anderem.</p>
                            <p>Schaue gerade aus. Messen Sie von der Mitte Ihrer Pupillen aus.</p>
                            <p><a href="https://paskamer.brilonline.com/static/liniaal.pdf" target="_blank">Drucklineal</a> (Hinweis: Drucker auf 100 % Druckgröße einstellen)</p>
                        </template>
                        <img src="@/assets/pupil-distance-measurement.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <button class="btn btn--secondary" @click="handleSidebar({type: 'pupil-distance', action: 'close'}); submitPupilDistance();">{{ $t('apply.pupil_distance') }}</button>
        </template>
    </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/components/sidebar/SidebarLayout';
import accordionFn from '@scripts/component/accordion';

export default {
    data() {
        return {
            pupilDistance: null,
            accordionTriggered: false,
        };
    },

    components: {
        SidebarLayout,
    },

    mounted() {
        this.triggerAccordionFn();
    },

    updated() {
        this.triggerAccordionFn();
    },

    watch: {
        pupilDistance() {
            this.commitPupilDistance();
        },
    },

    methods: {
        commitPupilDistance() {
            this.$store.commit('setPupilSizeUserInfo', this.pupilDistance);
        },

        submitPupilDistance() {
            this.$store.dispatch('updateUserPupilSize', this.pupilDistance);
        },

        triggerAccordionFn() {
            const items = document.querySelectorAll('.accordion__item');
            if (items.length > 0 && !this.accordionTriggered) {
                accordionFn();
                this.accordionTriggered = true;
            }
        },
    },
};
</script>

<template>
    <div class="drag-container" ref="container" :class="{ 'drag-container--touch' : fittingRoom.selected !== 'preview' }">
        <template v-if="getEnvironment === 'development'">
            <span class="marker" ref="leftEye"></span>
            <span class="marker" ref="rightEye"></span>
            <span class="marker" ref="noseTip"></span>
            <span class="marker" ref="noseRoot"></span>
            <span class="marker" ref="eyebrowRightCenter"></span>
            <span class="marker" ref="eyebrowLeftCenter"></span>
        </template>
        <cld-image :public-id="imagePath" v-show="fittingRoom.selected !== 'preview'">
            <cld-transformation gravity="face" :width="canvasSize.w" :height="canvasSize.h" crop="fill"></cld-transformation>
        </cld-image>
        <div class="preview-img" v-show="fittingRoom.selected === 'preview'">
            <img :src="fittingRoom.imageLocation.preview" alt="">
        </div>
        <div class="image-wrapper" ref="imageWrapper" id="resizable-element" v-show="fittingRoom.selected !== 'preview' && selectedGlass.media !== '' && showUI">
            <div class="rotate-wrapper">
                <button class="btn btn--io btn--rotate btn--secondary" ref="rotationHandler"><i class="icon-rotate"></i></button>
                <Tooltip :content="$t('tooltip.rotate')" />
            </div>
            <img :src="selectedGlass.media" ref="image" class="glasses-image" alt="">
        </div>
        <bottom-buttons-component v-show="fittingRoom.selected !== 'preview' && showUI" />
        <PupilSizeMessage v-if="showPupilSizeMessage"></PupilSizeMessage>
    </div>
</template>

<script>
import gsap from 'gsap';
import axios from 'axios';
import interact from 'interactjs';
import Snackbar from '@scripts/component/snackbar';
// Components
import BottomButtonsComponent from '@/components/navigation/bottomButtonsComponent';
import PupilSizeMessage from '@/components/navigation/pupilSizeMessage';
import Tooltip from '@/components/tooltip';

const snackbar = new Snackbar(undefined, document.querySelector('brilonline-widget'));

export default {
    components: {
        BottomButtonsComponent,
        PupilSizeMessage,
        Tooltip,
    },

    data() {
        return {
            canvasSize: {
                w: 960,
                h: 936,
            },
            options: ['c_fill', 'w_961', 'h_936'],
            landmarks: [],
            element: null,
            container: {
                ratio: 1,
                difference: 1,
            },
            model: {
                r_eye: {},
                l_eye: {},
                nose: {
                    tip: {},
                    root: {},
                },
                eyebrow: {
                    r_center: {},
                    l_center: {},
                },
                ratio: 1,
                eyesDistance: 0,
                eyesDegrees: 0,
            },
            glasses: {
                initialWidth: 576,
                initialHeight: 162,
                width: 0,
                height: 0,
                x: 0,
                y: 0,
                rotate: 0,
                ratio: 1,
            },
            userInput: {
                x: 0,
                y: 0,
                rotate: 0,
            },
            moving: {
                x: 0,
                y: 0,
                rotate: 0,
            },
        };
    },

    computed: {
        fittingRoom() {
            return this.$store.getters.getFittingRoom;
        },

        userInfo() {
            return this.$store.getters.getUserInfo;
        },

        imagePath() {
            return this.$store.getters.getImagePath;
        },

        selectedGlass() {
            return this.$store.getters.getSelectedGlass;
        },

        storageUserInput() {
            return this.$store.getters.getUserInput;
        },

        showUI() {
            return this.$store.getters.getShowUI;
        },

        userHasUploadedImage() {
            return this.$store.getters.getUserHasUploadedImage;
        },

        showPupilSizeMessage() {
            if (!this.imagePath) return false;
            if (!this.fittingRoom.glass.pupilSize) return false;
            const glassPupilSize = this.fittingRoom.glass.pupilSize;
            let glassFitsOnModel = false;
            let modelPupilSize = 0;
            if (this.fittingRoom.selected === 'model' && this.fittingRoom.model.pupilSize) {
                modelPupilSize = this.fittingRoom.model.pupilSize;
            } else if (this.userInfo.pupilSize) {
                modelPupilSize = this.userInfo.pupilSize;
            }
            if (glassPupilSize === modelPupilSize) {
                glassFitsOnModel = true;
            }
            return !glassFitsOnModel;
        },

        shareImage() {
            return this.$store.getters.getShareImage;
        },
    },

    methods: {
        placeGlassesOnCoordinates() {
            const pixelToMillimeter = 3.7795275591; // 1mm = 3.7795275591
            const widthCalculation = Math.round(this.selectedGlass.size * pixelToMillimeter * this.container.ratio);
            const ratio = widthCalculation / this.glasses.initialWidth;

            // Calculate container ratio difference (responsive) based on current and previous
            this.container.difference = (1 / this.container.ratio);

            // Calculations
            this.glasses.width = widthCalculation;
            this.glasses.height = Math.round(this.glasses.initialHeight * ratio);

            // Original values
            this.glasses.originalWidth = this.glasses.width * this.container.difference;
            this.glasses.originalHeight = this.glasses.height * this.container.difference;
            this.$refs.imageWrapper.dataset.originalWidth = this.glasses.originalWidth;
            this.$refs.imageWrapper.dataset.originalHeight = this.glasses.originalHeight;
            this.$refs.imageWrapper.dataset.originalX = (this.model.nose.root.x * this.container.difference) - (this.glasses.originalWidth / 2);
            this.$refs.imageWrapper.dataset.originalY = (this.model.eyebrow.r_center.y * this.container.difference) - 5;

            // Set image width and height
            this.$refs.imageWrapper.style.width = `${this.glasses.width}px`;
            this.$refs.imageWrapper.style.height = `${this.glasses.height}px`;

            // Set x and y coordinates
            this.glasses.x = this.model.nose.root.x - (this.glasses.width / 2);
            this.glasses.y = this.model.r_eye.y - (this.glasses.height / 2);

            // Create variables and override them if userInput contains data
            let x = this.glasses.x;
            let y = this.glasses.y;
            let angle = this.glasses.rotate;

            // Check and get user input
            if (this.userInput.x > 0) {
                x = this.userInput.x * this.container.ratio;
            }
            if (this.userInput.y > 0) {
                y = this.userInput.y * this.container.ratio;
            }
            if (this.userInput.rotate !== 0) {
                angle = this.userInput.rotate;
            }

            this.$refs.imageWrapper.dataset.originalX = x * this.container.difference;
            this.$refs.imageWrapper.dataset.originalY = y * this.container.difference;

            // Update data attributes
            this.element.setAttribute('data-angle', angle);

            // Trigger the moving function
            this.moveGlasses({
                x: x,
                y: y,
                angle: angle,
            });
        },

        getDragAngle(event) {
            const box = this.element;
            const startAngle = parseFloat(box.getAttribute('data-angle')) || 0;
            const center = {
                x: parseFloat(box.getAttribute('data-center-x')) || 0,
                y: parseFloat(box.getAttribute('data-center-y')) || 0,
            };
            const angle = Math.atan2(center.y - event.clientY, center.x - event.clientX);

            return angle - startAngle;
        },

        initInteract() {
            const self = this; /* eslint-disable-line */

            // Move
            interact(this.$refs.imageWrapper)
                .draggable({
                    modifiers: [
                        interact.modifiers.restrictRect({
                            restriction: 'parent',
                            endOnly: true,
                        }),
                    ],
                    onmove(event) {
                        self.moving.x = event.dx;
                        self.moving.y = event.dy;
                    },
                });

            // Rotate
            interact(this.$refs.rotationHandler)
                .draggable({
                    onstart: function (event) {
                        const box = self.element;
                        const rect = box.getBoundingClientRect();

                        // store the center as the element has css `transform-origin: center center`
                        box.setAttribute('data-center-x', rect.left + rect.width / 2);
                        box.setAttribute('data-center-y', rect.top + rect.height / 2);
                        // get the angle of the element when the drag starts
                        box.setAttribute('data-angle', -3.2419075);
                    },
                    onmove: function (event) {
                        // Update moving data values and trigger moving function
                        self.moving.rotate = self.getDragAngle(event);
                    },
                    onend: function (event) {
                        // Update the element attributes
                        self.element.setAttribute('data-angle', self.getDragAngle(event));
                    },
                });
        },

        moveGlasses(data) {
            let x, y, angle;

            if (this.userInput.x === this.moving.x && this.userInput.y === this.moving.y) {
                // Not moving, only rotate
                x = (parseFloat(this.element.getAttribute('data-x')) || 0);
                y = (parseFloat(this.element.getAttribute('data-y')) || 0);
            } else {
                // Moving
                x = (parseFloat(this.element.getAttribute('data-x')) || 0) + this.moving.x;
                y = (parseFloat(this.element.getAttribute('data-y')) || 0) + this.moving.y;
            }

            // Rotate
            angle = this.moving.rotate || parseFloat(this.element.getAttribute('data-angle')) || 0;

            // Overwrite if data is present
            // This is used for the initial position
            if (data) {
                x = data.x;
                y = data.y;
                angle = data.angle;
            }

            // Translate the element
            this.element.style.transform = `translate(${x}px, ${y}px) rotate(${(angle * 180 / Math.PI)}deg)`;

            // Update the element attributes
            this.element.setAttribute('data-x', x);
            this.element.setAttribute('data-y', y);

            // Save to userInput
            if (data) {
                return;
            }

            this.userInput.x = x * this.container.difference;
            this.userInput.y = y * this.container.difference;
            this.userInput.rotate = angle;

            this.$refs.imageWrapper.dataset.originalX = this.userInput.x;
            this.$refs.imageWrapper.dataset.originalY = this.userInput.y;
        },

        async initData() {
            return new Promise((resolve) => {
                this.element = this.$refs.imageWrapper;

                axios.get(`https://res.cloudinary.com/${process.env.VUE_APP_CLD_NAME}/image/upload/${this.options.join(',')},g_face/fl_getinfo/${this.imagePath}`).then((res) => {
                    if (!res.data.landmarks) {
                        snackbar.connected('Error while checking for a face.');
                        return false;
                    }

                    res.data.landmarks[0].forEach((item, index) => {
                        if (index === 0) {
                            Object.entries(item).forEach(([key, single]) => {
                                if (key.includes('eye') || key.includes('nose') || key.includes('eyebrow')) {
                                    this.landmarks[key] = single;
                                }
                            });
                        }
                    });

                    if (this.landmarks.r_eye === undefined) {
                        if (!this.landmarks.l_canthus_r_eye) {
                            snackbar.connected('Error while checking for right eye.');
                            return false;
                        }

                        this.landmarks.r_eye = {
                            x: this.landmarks.l_canthus_r_eye.x - ((this.landmarks.l_canthus_r_eye.x - this.landmarks.r_canthus_r_eye.x) / 2),
                            y: this.landmarks.l_canthus_r_eye.y - ((this.landmarks.l_canthus_r_eye.y - this.landmarks.r_canthus_r_eye.y) / 2),
                        };
                    }

                    if (this.landmarks.l_eye === undefined) {
                        if (!this.landmarks.l_canthus_l_eye) {
                            snackbar.connected('Error while checking for left eye.');
                            return false;
                        }

                        this.landmarks.l_eye = {
                            x: this.landmarks.l_canthus_l_eye.x - ((this.landmarks.l_canthus_l_eye.x - this.landmarks.r_canthus_l_eye.x) / 2),
                            y: this.landmarks.l_canthus_l_eye.y - ((this.landmarks.l_canthus_l_eye.y - this.landmarks.r_canthus_l_eye.y) / 2),
                        };
                    }

                    // Initial positioning
                    // Coordinates are calculated based on model initial width and height from the API. However, the model is scaled/cropped on render.
                    // So we need to use the ratio of the model to position correctly.
                    // For responsiveness, we also need the ratio of the container compared to the 1920px container.

                    this.container.ratio = this.$refs.container.offsetWidth / res.data.output.width;

                    this.model.ratio = {
                        x: res.data.resize[1].x_factor,
                        y: res.data.resize[1].y_factor,
                    };

                    this.model.nose.root = {
                        x: (this.landmarks.nose_root.x - res.data.resize[0].x) * this.container.ratio * this.model.ratio.x,
                        y: (this.landmarks.nose_root.y - res.data.resize[0].y) * this.container.ratio * this.model.ratio.y,
                    };
                    this.model.nose.tip = {
                        x: (this.landmarks.nose_tip.x - res.data.resize[0].x) * this.container.ratio * this.model.ratio.x,
                        y: (this.landmarks.nose_tip.y - res.data.resize[0].y) * this.container.ratio * this.model.ratio.y,
                    };
                    this.model.r_eye = {
                        x: (this.landmarks.r_eye.x - res.data.resize[0].x) * this.container.ratio * this.model.ratio.x,
                        y: (this.landmarks.r_eye.y - res.data.resize[0].y) * this.container.ratio * this.model.ratio.y,
                    };
                    this.model.l_eye = {
                        x: (this.landmarks.l_eye.x - res.data.resize[0].x) * this.container.ratio * this.model.ratio.x,
                        y: (this.landmarks.l_eye.y - res.data.resize[0].y) * this.container.ratio * this.model.ratio.y,
                    };
                    this.model.eyebrow = {
                        r_center: {
                            x: (this.landmarks.r_eyebrow_c.x - res.data.resize[0].x) * this.container.ratio * this.model.ratio.x,
                            y: (this.landmarks.r_eyebrow_c.y - res.data.resize[0].y) * this.container.ratio * this.model.ratio.y,
                        },
                        l_center: {
                            x: (this.landmarks.l_eyebrow_c.x - res.data.resize[0].x) * this.container.ratio * this.model.ratio.x,
                            y: (this.landmarks.l_eyebrow_c.y - res.data.resize[0].y) * this.container.ratio * this.model.ratio.y,
                        },
                    };

                    this.model.eyesDistance = this.fittingRoom.model.pupilSize;

                    if (this.getEnvironment === 'development') {
                        this.$refs.noseTip.style.transform = `translate(${this.model.nose.tip.x}px, ${this.model.nose.tip.y}px)`;
                        this.$refs.noseRoot.style.transform = `translate(${this.model.nose.root.x}px, ${this.model.nose.root.y}px)`;
                        this.$refs.leftEye.style.transform = `translate(${this.model.l_eye.x}px, ${this.model.l_eye.y}px)`;
                        this.$refs.rightEye.style.transform = `translate(${this.model.r_eye.x}px, ${this.model.r_eye.y}px)`;
                        this.$refs.eyebrowRightCenter.style.transform = `translate(${this.model.eyebrow.r_center.x}px, ${this.model.eyebrow.r_center.y}px)`;
                        this.$refs.eyebrowLeftCenter.style.transform = `translate(${this.model.eyebrow.l_center.x}px, ${this.model.eyebrow.l_center.y}px)`;
                    }

                    // Rotation
                    this.model.eyesDegrees = Math.atan2(this.landmarks.l_eye.y - this.landmarks.r_eye.y, this.landmarks.l_eye.x - this.landmarks.r_eye.x);
                    this.glasses.rotate = this.model.eyesDegrees;

                    if (this.storageUserInput) {
                        this.userInput = this.storageUserInput;
                    }

                    resolve();
                });
            });
        },

        getUserDataFromStorage() {
            return JSON.parse(localStorage.getItem(`${this.$store.getters.getLocalStorageKey}-userdata`));
        },
    },

    watch: {
        imagePath() {
            this.initData().then(() => {
                this.placeGlassesOnCoordinates();
                this.initInteract();
            });
        },

        moving: {
            deep: true,
            handler() {
                this.moveGlasses();

                if (this.shareImage.trim().length > 0) {
                    this.$store.dispatch('setShareImage', '');
                }
            },
        },

        userInput: {
            deep: true,
            handler() {
                this.$store.dispatch('saveUserInput', {
                    key: 'userInput',
                    data: this.userInput,
                });
            },
        },

        '$store.state.userInfo.pupilSize'() {
            localStorage.setItem(`${this.$store.getters.getLocalStorageKey}-userdata`, JSON.stringify(this.$store.state.userInfo));
        },

        '$store.state.fittingRoom.imageLocation.model'() {
            this.userInput.x = 0;
            this.userInput.y = 0;
            this.userInput.rotate = 0;
        },

        '$store.state.fittingRoom.imageLocation.user'() {
            this.userInput.x = 0;
            this.userInput.y = 0;
            this.userInput.rotate = 0;
        },

        '$store.state.fittingRoom': {
            deep: true,
            handler() {
                localStorage.setItem(`${this.$store.getters.getLocalStorageKey}-fittingRoom`, JSON.stringify(this.$store.state.fittingRoom));

                if (this.imagePath.trim().length > 0) {
                    gsap.to(
                        this.$refs.imageWrapper,
                        {
                            autoAlpha: 0,
                            duration: 0.25,
                            ease: 'linear',
                        },
                    );

                    this.initData().then(() => {
                        gsap.to(
                            this.$refs.imageWrapper,
                            {
                                autoAlpha: 1,
                                duration: 0.25,
                                ease: 'linear',
                                onStart: () => {
                                    this.placeGlassesOnCoordinates();
                                },
                            },
                        );
                    });
                }
            },
        },
    },

    mounted() {
        let setView = this.fittingRoom.selected;
        if (this.userHasUploadedImage) {
            setView = 'user';
        } else if (this.$route.name === 'single') {
            setView = 'preview';
        }

        if (this.getUserDataFromStorage()) {
            this.$store.commit('setUserInfo', this.getUserDataFromStorage());
        }

        this.$store.dispatch('changeFittingRoomView', setView);
        if (this.imagePath.trim().length > 0) {
            this.initData().then(() => {
                this.placeGlassesOnCoordinates();
                this.initInteract();
            });
        }
    },
};
</script>

<style lang="scss" scoped v-if="getEnvironment === 'development'">
.marker {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 8px;
    margin-top: 0 !important;
    border: 1px solid rgba(red, .7);
    border-radius: 50%;
    background-color: rgba(red, .3);
    position: absolute;
    top: 0;
    left: -4px;

    &::after {
        content: '';
        width: 2px;
        height: 2px;
        background-color: white;
    }
}
</style>

<template>
    <div class="empty-view">
        <div class="empty-view__content">
            <template v-if="$i18n.locale === 'nl'">
                <h1>Welkom bij {{ getName }}</h1>
                <p>Online brillen passen op jouw eigen gezicht.</p>
                <p>Start hier om jouw perfecte bril te vinden.</p>
                <p>Maak een foto van jezelf of<br/>kies een model.</p>
            </template>
            <template v-else-if="$i18n.locale === 'de'">
                <h1>Wilkommen bei {{ getName }}</h1>
                <p>Online-Brillen passen zum eigenen Gesicht.</p>
                <p>Starten Sie hier, um Ihre perfekte Brille zu finden.</p>
                <p>Machen Sie ein Foto von sich oder<br/>wählen Sie ein Model aus.</p>
            </template>
            <template v-else>
                <h1>Welcome at {{ getName }}</h1>
                <p>Online glasses fit your own face.</p>
                <p>Start here to find your perfect glasses.</p>
                <p>Take a picture of yourself or<br/>choose a model.</p>
            </template>
        </div>
        <div class="empty-view__buttons">
            <button class="btn btn--il btn--primary" @click="handleSidebar({type: 'user-face'})">
                <i class="icon-fitting-room"></i>
                <span>{{ $t('upload.photo') }}</span>
            </button>
            <span class="divider">{{ $t('message.or') }}</span>
            <button class="btn btn--il btn--primary" @click="handleSidebar({type: 'models'})">
                <i class="icon-face"></i>
                <span>{{ $t('models.short') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        getName() {
            return this.$store.getters.getData.name;
        },
    },
};
</script>

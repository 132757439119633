<template>
    <div class="card card--product" :class="{ 'card--selected' : item.uid === selectedGlass.uid }">
        <router-link :to="{ name: 'single', params: { slug: item.slug }}" class="card__anchor"></router-link>
        <div class="actions-wrapper">
            <div class="action action--btn action--favorite" @click="addToFavorites">
                <i :class="isFavorite ? 'icon-heart-filled' : 'icon-heart'"></i>
            </div>
        </div>
        <div class="inner">
            <img :src="image.media" alt="">
        </div>
        <div class="card__button-wrapper">
            <router-link
                v-if="!isTouchScreen && !this.$store.getters.getUserHasUploadedImage"
                v-show="imagePath.trim().length > 0"
                :to="{ name: 'single', params: { slug: item.slug }}"
                class="btn btn--o btn--mercury"
                :class="{ 'btn--selected' : item.uid === selectedGlass.uid }"
                @click.native="setFittedGlass"
            >
                <span v-show="item.uid !== selectedGlass.uid">{{ $t('apply.model') }}</span>
                <span v-show="item.uid === selectedGlass.uid">{{ $t('apply.selected') }}</span>
            </router-link>
            <button
                v-if="!isTouchScreen && this.$store.getters.getUserHasUploadedImage"
                v-show="imagePath.trim().length > 0"
                class="btn btn--o btn--mercury"
                :class="{ 'btn--selected' : item.uid === selectedGlass.uid }"
                @click="changeFittingRoomGlasses(); setFittedGlass();">
                <span v-show="item.uid !== selectedGlass.uid">{{ $t('apply.glasses') }}</span>
                <span v-show="item.uid === selectedGlass.uid">{{ $t('apply.selected') }}</span>
            </button>
            <router-link :to="{ name: 'single', params: { slug: item.slug }}" class="btn" :class="{ active : isInCart }">
                <span>{{ item.brand.name }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],

    computed: {
        imagePath() {
            return this.$store.getters.getImagePath;
        },

        isFavorite() {
            return this.$store.getters.getFavorites.includes(this.item.slug);
        },

        isInCart() {
            return this.$store.getters.getCart.includes(this.item.slug);
        },

        selectedGlass() {
            return this.$store.getters.getSelectedGlass;
        },

        image() {
            return this.item.images.side2;
        },
    },

    methods: {
        addToFavorites() {
            this.$store.dispatch(this.isFavorite ? 'removeFromFavorites' : 'addToFavorites', this.item.slug);
        },

        changeFittingRoomGlasses() {
            this.$store.dispatch('changeFittingRoomGlasses', {
                media: this.item.images.front.media,
                size: this.item.sizes.width,
                uid: this.item.uid,
                pupilSize: this.item.sizes.pupil,
            });
        },

        setFittedGlass() {
            this.$store.dispatch('postGlassesFit', this.item.uid);
        },
    },
};
</script>

<template>
    <div class="vue-container section--overview">
        <div class="intro">
            <div class="intro__header">
                <h3>{{ data.name }}</h3>
                <p class="body--small" v-show="Object.entries(glasses).length > 0">{{ Object.entries(glasses).length }}
                    {{ Object.entries(glasses).length > 1 ? $t('message.glasses') : $t('message.glass') }}</p>
            </div>
            <button class="btn btn--secondary action" @click="handleSidebar({type: 'filter'})"
                    :disabled="Object.keys(filters).length === 0 || !glassesLoaded">
                {{ $t('filters.modal') }}
            </button>

            <div class="filters" v-if="!(Object.keys(filters).length === 0 || !glassesLoaded)">
                <div class="button-wrapper" v-if="Object.keys(activeFilters).length > 0">
                    <template v-for="(item, key) in activeFilters">
                        <template v-for="(filter, filterKey) in item">
                            <filterButton :filter="filter" :parent="key" :key="filterKey[key]" />
                        </template>
                    </template>
                </div>
            </div>
        </div>
        <div class="overview__container" ref="scrollContainer" @scroll="saveScrollPosition">
            <div class="row">
                <div class="col-6" v-for="(item, key) in glasses" :key="item.uid + key">
                    <ProductCard :item="item"></ProductCard>
                </div>
                <template v-if="(glasses && Object.keys(glasses).length) <= maxSkeletonCards && !glassesLoaded">
                    <div class="col-6" v-for="i in (maxSkeletonCards - Object.keys(glasses).length)" :key="i">
                        <SkeletonCard></SkeletonCard>
                    </div>
                </template>
                <div v-if="Object.keys(glasses).length === 0 && glassesLoaded">
                    {{ $t('message.no_results_found') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from '@/components/cards/productCard.vue';
import SkeletonCard from '@scripts/vue/components/cards/skeletonCard';
import filterButton from '@/components/filters/filterButton';

export default {
    data() {
        return {
            maxSkeletonCards: 6,
        };
    },
    components: {
        ProductCard,
        SkeletonCard,
        filterButton,
    },

    computed: {
        data() {
            return this.$store.getters.getData;
        },

        glasses() {
            return this.$store.getters.getFilteredObjects;
        },

        filters() {
            return this.$store.getters.getFilters;
        },

        getScrollPosition() {
            return this.$store.getters.getScrollPosition;
        },

        glassesLoaded() {
            return this.$store.getters.getGlassesLoaded;
        },
        activeFilters() {
            return this.$store.getters.getActiveFilters;
        },
    },

    mounted() {
        if (this.getScrollPosition > 0) {
            this.$refs.scrollContainer.scrollTop = this.getScrollPosition;
        }
    },

    methods: {
        saveScrollPosition(evt) {
            this.$store.dispatch('updateScrollPosition', evt.target.scrollTop);
        },
    },
};
</script>

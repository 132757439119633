import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.mixin({
    computed: {
        ...mapGetters([
            'isWebsiteOrigin',
            'getEnvironment',
        ]),
    },
});

<template>
    <div class="vue-container" v-if="item">
        <div class="glasses-view">
            <header class="glasses-view__header">
                <h3 class="title">{{ item.brand.name }}</h3>
                <span class="status">{{ item.supply.label }}</span>
                <span class="price body--small" v-html="item.price.format"></span>
            </header>
            <div class="glasses-view__gallery" ref="singleThumbsSlider">
                <div class="slide">
                    <figure
                        class="gallery-item gallery-item--view bg--secondary"
                        :class="{ 'selected': (fittingRoomView !== 'preview') }"
                        @click="selectImage(null, $event.target, true); setFittedGlass();"
                    >
                        <i class="icon-reload"></i>
                        <span v-if="userHasUploadedImage">{{ $t('apply.gallery_user') }}</span>
                        <span v-else-if="imagePath === ''">{{ $t('apply.gallery_fittingroom') }}</span>
                        <span v-else>{{ $t('apply.gallery_model') }}</span>
                    </figure>
                </div>
                <div class="slide" v-for="(thumb, key) in images" :key="'slide-' + key">
                    <figure class="gallery-item" @click="selectImage(key, $event.currentTarget)" :class="{ 'selected': (fittingRoomView === 'preview' && key === 0) }">
                        <img :src="thumb[1].media" :alt="item.brand.name">
                    </figure>
                </div>
            </div>
            <div class="glasses-view__tabs">
                <tabs-wrapper>
                    <tab-component :title="$t('tabs.brand')" v-if="brandDescription()" v-html="brandDescription()"></tab-component>
                    <tab-component :title="$t('tabs.frame')">
                        <ul class="property-list style-none">
                            <li v-for="(property, key) in listOfProperties()" :key="key">
                                <span class="property-list__title">
                                    {{ $t(property.type) }}
                                </span>
                                <span class="property-list__value">{{ property.value }}</span>
                            </li>
                        </ul>
                    </tab-component>
                    <tab-component :title="$t('tabs.sizing')" v-if="item.sizes.width > 0 || item.sizes.length > 0">
                        <div class="sizing-info-wrapper">
                            <div class="sizing-info">
                                <img src="@/assets/bril-maatvoering-front.png" alt="">
                                <span class="sizing-info__width" v-show="item.sizes.width">{{ $t('sizing.width') }} {{ item.sizes.width }}mm</span>
                                <span class="sizing-info__nose" v-show="item.sizes.nose">{{ $t('sizing.nose') }} {{ item.sizes.nose }}mm</span>
                                <span class="sizing-info__diameter" v-show="item.sizes.diameter">{{ $t('sizing.diameter') }} {{ item.sizes.diameter }}mm</span>
                            </div>
                            <div class="sizing-info">
                                <img src="@/assets/bril-maatvoering-side.png" alt="">
                                <span class="sizing-info__length" v-show="item.sizes.length">{{ $t('sizing.length') }} {{ item.sizes.length }}mm</span>
                            </div>
                        </div>
                    </tab-component>
                    <tab-component :title="$t('tabs.glass')" v-if="data.descriptions.glass" v-html="data.descriptions.glass"></tab-component>
                    <tab-component :title="$t('tabs.method')" v-if="data.descriptions.method" v-html="data.descriptions.method"></tab-component>
                </tabs-wrapper>
            </div>
        </div>
        <div class="button-wrapper">
            <button class="btn btn--md btn--primary btn--il" v-if="!userHasUploadedImage" @click="handleSidebar({type: 'user-face'})">
                <i class="icon-fitting-room"></i><span>{{ $t('upload.own') }}</span>
            </button>
            <button class="btn btn--md btn--secondary" @click="addToCart" v-if="!isInCart" :disabled="cart.length >= 5">
              <span>{{ $t('cart.button_reserve_obligation') }}</span>
            </button>
            <button class="btn btn--md btn--secondary" @click="removeFromCart" v-if="isInCart" v>
                <span>{{ $t('cart.remove') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import pluralize from 'pluralize';
import tabsWrapper from '@/components/tabs/tabsWrapper';
import tabComponent from '@/components/tabs/tabComponent';

export default {
    components: {
        tabComponent,
        tabsWrapper,
    },

    data() {
        return {
            created: false,
        };
    },

    computed: {
        data() {
            return this.$store.getters.getData;
        },

        item() {
            return this.$store.getters.getItemById(this.$route.params.slug);
        },

        brands() {
            return this.$store.getters.getBrands;
        },

        userHasUploadedImage() {
            return this.$store.getters.getUserHasUploadedImage;
        },

        cart() {
            return this.$store.getters.getCart;
        },

        isInCart() {
            if (this.item) {
                return this.$store.getters.getCart.includes(this.item.slug);
            } else {
                return false;
            }
        },

        fittingRoomView() {
            return this.$store.getters.getFittingRoom.selected;
        },

        imagePath() {
            return this.$store.getters.getImagePath;
        },

        images() {
            return Object.entries(this.item.images).filter((entry) => {
                const [key, value] = entry;
                return key.includes('thumb') && value.media;
            });
        },
    },

    mounted() {
        this.setFittingRoomGlasses();
    },

    methods: {
        setFittedGlass() {
            this.$store.dispatch('postGlassesFit', this.item.uid);
        },

        brandDescription() {
            if (Object.keys(this.brands).length > 0) {
                const brand = Array.from(this.brands).find((b) => b.slug === this.item.brand.slug);
                if (typeof brand !== 'undefined') {
                    return brand.description.full;
                }
            }

            return null;
        },

        listOfProperties() {
            const properties = this.item.filters;
            const neededList = ['gender', 'materials', 'colors', 'shapes', 'sturdinesses', 'styles'];
            const propertyKeys = neededList.filter((key) => Object.keys(properties).includes(key));
            const references = [];

            propertyKeys.forEach((key) => {
                const setSingularKey = key === 'gender' ? 'categories' : key;
                const setUcFirst = setSingularKey.charAt(0).toUpperCase() + setSingularKey.slice(1);
                references.push({
                    type: pluralize.singular(setUcFirst),
                    value: properties[key].map(n => n.name).join(', '),
                });
            });

            return references;
        },

        addToCart() {
            this.$store.dispatch('addToCart', this.item.slug);
        },

        removeFromCart() {
            this.$store.dispatch('removeFromCart', this.item.slug);
        },

        selectImage(index, target, showUI = false) {
            Array.from(this.$refs.singleThumbsSlider.children).forEach((item) => {
                item.querySelector('.gallery-item').classList.remove('selected');
            });
            target.classList.add('selected');

            if (index !== null) {
                this.$store.dispatch('changeFittingRoomPreview', this.item.images[`side${index + 1}`].media);
                this.$store.dispatch('changeFittingRoomView', 'preview');
            } else {
                let setView = null;
                if (this.userHasUploadedImage) {
                    setView = 'user';
                } else if (this.imagePath !== '') {
                    setView = 'model';
                }
                this.$store.dispatch('changeFittingRoomView', setView);
            }
            this.$store.dispatch('showUI', showUI);
        },

        setFittingRoomGlasses() {
            if (this.item && !this.created) {
                this.created = true;
                this.$store.dispatch('changeFittingRoomPreview', this.item.images.side1.media);
                this.$store.dispatch('changeFittingRoomGlasses', {
                    media: this.item.images.front.media,
                    size: this.item.sizes.width,
                    uid: this.item.uid,
                    pupilSize: this.item.sizes.pupil,
                });
            }
        },
    },

    updated() {
        this.setFittingRoomGlasses();
    },
};
</script>

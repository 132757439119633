<template>
    <div class="vue-container section--optician">
        <div class="text">
            <h3 class="title">{{ $t('optician.title') }}</h3>
            <p class="body--small" v-html="data.descriptions.optician_search"></p>
        </div>
        <div class="search">
            <div class="search-wrapper">
                <input type="text" :placeholder="$t('optician.search')" v-model="searchInput">
            </div>
        </div>
        <div class="optician-list">
            <div class="accordion-holder" v-show="searchInput && searchInput.trim().length > 0">
                <optician-accordion v-for="(item, index) in list" :item="item" :key="index"
                                    :accordionType="accordionType" ref="accordionItem"></optician-accordion>
            </div>
        </div>
        <router-link v-show="getActiveOptician" :to="{ name: 'summary' }" class="btn btn--md btn--secondary btn--next"
                     :class="{ disabled : disabled }">
            <span>{{ $t('message.next') }}</span>
        </router-link>
    </div>
</template>

<script>
import accordionFn from '@scripts/component/accordion';
import opticianAccordion from '@/components/cards/opticianAccordion';
import { debounce } from 'debounce';
import axios from 'axios';

export default {
    components: {
        opticianAccordion,
    },

    data() {
        return {
            accordionType: 'selectOptician',
            accordionTriggered: false,
            searchInput: null,
        };
    },

    computed: {
        data() {
            return this.$store.getters.getData;
        },

        list() {
            return this.$store.getters.getListOpticians;
        },

        disabled() {
            return !(this.$store.getters.getActiveOptician !== null && this.$store.getters.getActiveOptician !== undefined);
        },

        getActiveOptician() {
            return this.$store.getters.getActiveOptician;
        },

        opticianSearchInput() {
            return this.$store.getters.getOpticianSearchInput;
        },
    },

    mounted() {
        this.triggerAccordionFn();
        this.setFirstOpticianActive();
        this.$store.dispatch('getLocalStorageDataForOpticians');
        this.searchInput = this.opticianSearchInput;
    },

    updated() {
        this.triggerAccordionFn();
    },

    watch: {
        searchInput() {
            this.$store.commit('setUserLocation', {});
            this.$store.dispatch('searchForOptician', '');

            if (this.searchInput.trim().length > 1) {
                this.debounceInput();
            }
        },
    },

    methods: {
        triggerAccordionFn() {
            const items = document.querySelectorAll('.accordion__item');
            if (items.length > 0 && !this.accordionTriggered) {
                accordionFn();
                this.accordionTriggered = true;
            }
        },

        setFirstOpticianActive() {
            const slug = this.list[0]?.slug;
            if (this.list && Object.keys(this.list).length === 1 && slug !== undefined) {
                this.$store.dispatch('setActiveOptician', slug);
            }
        },

        debounceInput: debounce(function () {
            axios.get('//nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: this.searchInput,
                    countrycodes: 'nl,de,be',
                },
            }).then((resp) => {
                const userLocation = {
                    address: resp.data[0].display_name,
                    coordinates: {
                        lat: Number(resp.data[0].lat),
                        lng: Number(resp.data[0].lon),
                    },
                };
                this.$store.commit('setUserLocation', userLocation);
            });
            this.$store.dispatch('searchForOptician', this.searchInput);
        }, 500),
    },
};
</script>

import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router';
import store from './store';
import i18n from './i18n';
import './cloudinary.js';
import handleSidebarMixin from '@/mixins/handleSidebar';
import globalGetter from '@/mixins/globalGetter';
import isMobile from '@/mixins/isMobile';
import vueCustomElement from 'vue-custom-element';

Vue.use(vueCustomElement);
Vue.use(VueAxios, axios);

// @ts-ignore
App.store = store;
// @ts-ignore
App.router = router;
// @ts-ignore
App.i18n = i18n;
// @ts-ignore
App.mixins = [handleSidebarMixin, globalGetter, isMobile];
Vue.customElement('brilonline-widget', App);

<template>
    <div class="vue-container section--personal-information">
        <form id="personal-information-form" @submit.prevent>
            <div class="form-group form-group--radio">
                <legend>{{ $t('formorder.gender') }}</legend>
                <label class="custom-control custom-control--radio">
                    <input type="radio" class="custom-control__input" name="gender" v-model="formData.gender" value="male" required :disabled="formSubmitted">
                    <span class="custom-control__indicator"></span>
                    <span class="custom-control__text">{{ $t('formorder.male') }}</span>
                </label>
                <label class="custom-control custom-control--radio">
                    <input type="radio" class="custom-control__input" name="gender" v-model="formData.gender" value="female" required :disabled="formSubmitted">
                    <span class="custom-control__indicator"></span>
                    <span class="custom-control__text">{{ $t('formorder.female') }}</span>
                </label>
            </div>
            <div class="row form-row form-row--spacing-top">
                <div class="col-sm-6">
                    <div class="form-group">
                        <input id="first_name" type="text" class="form-control" name="first_name" :placeholder="$t('formorder.firstName')" v-model="formData.first_name" :disabled="formSubmitted" required>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input id="last_name" type="text" class="form-control" name="last_name" :placeholder="$t('formorder.lastName')" v-model="formData.last_name" :disabled="formSubmitted" required>
                    </div>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="email" class="form-control" name="email" v-model="formData.email" :placeholder="$t('formorder.emailAddress')" required :disabled="formSubmitted">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="tel" class="form-control" name="phone" v-model="formData.phone" :placeholder="$t('formorder.phoneNumber')" required :disabled="formSubmitted">
                    </div>
                </div>
            </div>
            <div class="form-row form-row--spacing-top">
                <div class="form-group form-group--radio">
                    <legend>{{ $t('formorder.opticianContact') }}</legend>
                    <label class="custom-control custom-control--radio">
                        <input type="radio" class="custom-control__input" name="contact-type" id="contact-phone" v-model="formData.contact_type" value="phone" required :disabled="formSubmitted">
                        <span class="custom-control__indicator"></span>
                        <span class="custom-control__text">{{ $t('formorder.opticianContactTelephone') }}</span>
                    </label>

                    <label class="custom-control custom-control--radio">
                        <input type="radio" class="custom-control__input" name="contact-type" id="contact-email" v-model="formData.contact_type" value="email" required :disabled="formSubmitted">
                        <span class="custom-control__indicator"></span>
                        <span class="custom-control__text">{{ $t('formorder.opticianContactEmail') }}</span>
                    </label>
                </div>
            </div>
            <div class="form-row form-row--spacing-top">
                <div class="form-group">
                    <label class="custom-control custom-control--checkbox">
                        <input type="checkbox" class="custom-control__input" name="free-measurement" v-model="formData.measurement" :disabled="formSubmitted">
                        <span class="custom-control__indicator"></span>
                        <span class="custom-control__text">{{ $t('formorder.freeMeasurement') }}</span>
                    </label>
                </div>
                <div class="form-group">
                    <label class="custom-control custom-control--checkbox">
                        <input type="checkbox" class="custom-control__input" name="keep-informed" v-model="formData.keep_informed" :disabled="formSubmitted">
                        <span class="custom-control__indicator"></span>
                        <span class="custom-control__text">{{ $t('formorder.keepInformed') }}</span>
                    </label>
                </div>

                <div class="form-group">
                    <label class="custom-control custom-control--checkbox">
                        <input type="checkbox" class="custom-control__input" name="control-checkbox"
                               id="control-checkbox" value="custom control-checkbox" v-model="formData.privacy" required :disabled="formSubmitted">
                        <span class="custom-control__indicator"></span>
                        <span class="custom-control__text">{{ $t('formorder.privacy') }}</span>
                    </label>
                </div>
            </div>
            <button type="submit" class="btn btn--md btn--secondary btn--next" :class="{ 'disabled': formSubmitted }" @click="submitForm" disabled>{{ $t('formorder.buttonLabel') }}</button>
          <Loader :color="'#000000'" :width="'60px'" v-if="formSubmitted"></Loader>
        </form>
    </div>
</template>

<script>
import validation from '@scripts/form/validation';
import Loader from '@/components/Loader';

export default {
    data() {
        return {
            form: '',
            invalidFields: [],
            formData: {
                first_name: null,
                last_name: null,
                gender: null,
                email: null,
                phone: null,
                measurement: false,
                keep_informed: false,
                privacy: false,
                contact_type: null,
            },
          formSubmitted: false,
        };
    },
  components: {
    Loader,
  },

    methods: {
        openStorage() {
            return JSON.parse(localStorage.getItem(`${this.$store.getters.getLocalStorageKey}-userdata`));
        },

        saveStorage(form) {
            localStorage.setItem(`${this.$store.getters.getLocalStorageKey}-userdata`, JSON.stringify(form));
        },

        submitForm() {
            this.saveStorage(this.formData);
            this.$store.commit('setUserInfo', this.formData);

            setTimeout(() => {
                this.invalidFields = this.form.querySelectorAll('.form-group--invalid');
                if (this.invalidFields.length === 0 && !this.formSubmitted) {
                  this.formSubmitted = true;
                  this.$store.dispatch('placeOrderAsync');
                }
            }, 250);
        },
    },

    mounted() {
        this.$store.dispatch('getLocalStorageDataForOpticians');
        this.form = document.getElementById('personal-information-form');
        validation();
        const storedForm = this.openStorage();
        if (storedForm) {
            this.formData = {
                ...this.formData,
                ...storedForm,
            };
        }
    },
};
</script>

<template>
    <SidebarLayout type="filter" v-if="Object.keys(filters).length > 0">
        <template #header>
            <button class="btn-remove-filters btn btn--sm btn--secondary btn--il" @click="deleteAllFilters()" v-show="activeFiltersLength > 0"><i class="icon-close"></i>{{ $t('filters.remove') }}</button>
        </template>
        <div class="filters">
            <div class="filter__item" v-for="(item, key) in filters" :key="key">
                <div class="filter__item__title">{{ item.name }}</div>
                <div class="button-wrapper">
                    <template v-for="(filter, filterKey) in item.filters">
                        <filterButton :filter="filter" :parent="item.slug" :key="filterKey"/>
                    </template>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn--secondary" @click="handleSidebar({type: 'filter', action: 'close'})">{{ $t('message.apply') }}</button>
        </template>
    </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/components/sidebar/SidebarLayout';
import filterButton from '@/components/filters/filterButton';

export default {
    components: {
        SidebarLayout,
        filterButton,
    },

    computed: {
        filters() {
            return this.$store.getters.getFilters;
        },

        activeFiltersLength() {
            return this.$store.getters.getNumberOfActiveFilters;
        },
    },

    methods: {
        deleteAllFilters() {
            this.$store.dispatch('deleteAllFiltersAsync');
        },
    },
};
</script>

import Vue from 'vue';

export default Vue.mixin({
    computed: {
        isTouchScreen() {
            let isTouchScreen = false;
            if (window.innerWidth < 768) {
                if ('maxTouchPoints' in navigator) {
                    isTouchScreen = navigator.maxTouchPoints > 0;
                } else {
                    const mQ = matchMedia('(pointer:coarse)');
                    if (mQ && mQ.media === '(pointer:coarse)') {
                        isTouchScreen = mQ.matches;
                    } else if ('orientation' in window) {
                        isTouchScreen = true;
                    } else {
                        const UA = navigator.userAgent;
                        isTouchScreen = (
                            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
                        );
                    }
                }
            }

            return isTouchScreen;
        },
    },
});

<template>
    <div class="vue-container section--thankyou">
        <h3>{{ $t('thankyou.title') }}</h3>

        <div class="section--thankyou__body">
            <p v-if="intro()">{{ intro() }}</p>

            <div class="thankyou-steps">
                <div class="thankyou-steps__item">
                    <div class="thankyou-steps__counter h5">1.</div>
                    <div class="thankyou-steps__body">
                        <h5>{{ data.descriptions.order.step1 }}</h5>
                    </div>
                </div>
                <div class="thankyou-steps__item">
                    <div class="thankyou-steps__counter h5">2.</div>
                    <div class="thankyou-steps__body">
                        <h5>{{ data.descriptions.order.step2 }}</h5>
                    </div>
                </div>
                <div class="thankyou-steps__item">
                    <div class="thankyou-steps__counter h5">3.</div>
                    <div class="thankyou-steps__body">
                        <h5>{{ data.descriptions.order.step3 }}</h5>
                    </div>
                </div>
            </div>
        </div>

        <router-link :to="{ name: 'overview' }" class="btn btn--md btn--secondary btn--next">
            <span>{{ $t('message.backToHomepage') }}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    computed: {
        data() {
            return this.$store.getters.getData;
        },

        userInfo() {
            return this.$store.getters.getUserInfo;
        },
    },

    methods: {
        intro() {
            if (this.userInfo.first_name) {
                return `${this.userInfo.first_name}, ${this.$t('thankyou.intro')}:`;
            }

            return null;
        },
    },
};
</script>

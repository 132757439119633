<template>
    <div class="vue-container section--summary" v-if="opticians.length > 0">
        <h3>{{ $t('summary.title') }}</h3>

        <div class="optician-list">
            <div class="accordion-holder">
                <optician-accordion
                    :item="data"
                    :accordionType="accordionType"
                    ref="accordionItem"
                />
            </div>
        </div>

        <div class="glasses-wrapper">
            <h4>{{ $t('summary.glassesListTitle') }}</h4>
            <div class="glasses-list__wrapper">
                <div class="glasses-list row">
                    <div class="col-6 col-md-4 glasses-list__col" v-for="item in cart" :key="item.slug">
                        <ProductCardSimple :item="item"></ProductCardSimple>
                    </div>
                    <div class="col-6 col-md-4 glasses-list__col">
                        <AddCard></AddCard>
                    </div>
                </div>
            </div>
        </div>

        <router-link :to="{ name: 'personalinformation' }" class="btn btn--md btn--secondary btn--next">
            <span>{{ $t('message.next') }}</span>
        </router-link>
    </div>
</template>

<script>
import accordionFn from '@scripts/component/accordion';
import opticianAccordion from '@/components/cards/opticianAccordion';
import ProductCardSimple from '@/components/cards/productCardSimple.vue';
import AddCard from '@/components/cards/addCard.vue';

export default {
    components: {
        opticianAccordion,
        ProductCardSimple,
        AddCard,
    },

    data() {
        return {
            accordionType: 'summary',
        };
    },

    computed: {
        opticians() {
            return this.$store.getters.getOpticians;
        },

        list() {
            return this.$store.getters.getListOpticians;
        },

        getSingleOpticianView() {
            return this.$store.getters.getSingleOpticianView;
        },

        data() {
            return this.opticians.find((item) => item.slug === this.$store.getters.getActiveOptician);
        },

        cart() {
            return Object.values(this.$store.getters.getOriginalGlasses).filter((item) => this.$store.getters.getCart.includes(item.slug));
        },
    },

    mounted() {
        this.triggerAccordionFn();
        this.setOpticianActive();
        this.$store.dispatch('getLocalStorageDataForOpticians');
    },

    methods: {
        triggerAccordionFn() {
            const items = document.querySelectorAll('.accordion__item');
            if (items.length > 0 && !this.accordionTriggered) {
                accordionFn();
                this.accordionTriggered = true;
            }
        },

        setOpticianActive() {
            if (this.getSingleOpticianView) {
                const slug = this.list[0]?.slug;
                if (this.list && Object.keys(this.list).length === 1 && slug !== undefined) {
                    this.$store.dispatch('setActiveOptician', slug);
                }
            }
        },
    },
};
</script>

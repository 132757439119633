import { show, hide } from '../util/show-hide';
import getParents from '../util/getParents';

const closeAllExceptCurrentEvent = (el, itemsByClassName, termElement, descriptionElement) => {
    let i;
    let j;
    const elements = document.getElementsByClassName(itemsByClassName);
    const arr = [];

    for (i = 0; i < elements.length; i += 1) {
        arr.push(elements[i]);

        if (elements[i] === el.parentElement) {
            arr.splice(i, 1);
        }
    }

    for (j = 0; j < arr.length; j += 1) {
        const description = arr[j].querySelector(descriptionElement);

        arr[j].classList.remove('open');
        hide(description);
    }
};

const accordion = () => {
    const accordionHolder = document.querySelectorAll('.accordion-holder');
    if (!accordionHolder.length > 0) {
        return;
    }

    let animating = false;
    Array.from(document.querySelectorAll('.accordion__item')).forEach((el) => {
        el.querySelector('.accordion__term').addEventListener('click', () => {
            const collapse = el.querySelector('.accordion__body');
            const section = getParents(el, 'section');
            if (animating) {
                return false;
            }

            if (collapse.classList.contains('show')) {
                hide(collapse);
            } else {
                show(collapse);

                if (!section || section.classList.contains('close-others')) {
                    closeAllExceptCurrentEvent(el, 'accordion__item', '.accordion__term', '.accordion__body');
                } else {
                    el.classList.add('open');
                    return false;
                }
            }

            el.classList.toggle('open');

            animating = true;

            setTimeout(() => {
                animating = false;
            }, 350);
        });
    });
};

export { accordion as default, closeAllExceptCurrentEvent };

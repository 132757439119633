<template>
    <div class="step step--intro">
        <div class="step__content">
            <figure>
                <img src="@/assets/sidebar-user-face-visual.png" alt="">
            </figure>
            <h4>{{ $t('upload.step_intro') }}</h4>
            <p>{{ $t('upload.step_intro_description') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepIntro',
};
</script>

<template>
    <div class="tooltip">
        <p>{{ content }}</p>
    </div>
</template>

<script>
export default {
    name: 'TooltipComponent',

    props: {
        content: {
            type: String,
            required: true,
        },
    },
};
</script>

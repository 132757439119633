<template>
    <div id="main" class="brilonline-widget-view brilonline-widget-view--no-cookie">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10">
                    <div class="no-cookie-wrapper">
                        <h1>You have your cookie settings disabled.</h1>
                        <p>Please enable cookies to make use of this tool. Visit this page for more information on how to enable cookies</p>
                        <a class="btn btn--secondary action" target="_blank" href="https://nap.nationalacademies.org/content/enabling-cookies-in-your-browser">Visit page</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    computed: {},
};
</script>

<template>
    <nav class="navigation">
        <button class="btn btn--md btn--il btn--back btn--wild-sand" @click="$router.go(-1)" v-if="showButton">
            <i class="icon-chevron-left"></i>
            <span>{{ $t('message.back') }}</span>
        </button>
        <button class="btn btn--md btn--back btn--wild-sand" v-if="getEnvironment === 'development'" @click="restartApp()"><span>{{ $t('message.restart') }}</span></button>
        <div class="navbar__actions">
            <router-link :to="{ name: 'order' }" class="btn btn--md btn--il btn--secondary" v-show="cartCount > 0">
                <i class="icon-heart-filled"></i>
                <span class="d-none d-lg-block">{{ $t('cart.button') }}</span>
                <span class="d-lg-none">{{ $t('cart.button_short') }}</span>
            </router-link>
            <button class="btn btn--md btn--il btn--wild-sand action action--favorite"
                    @click="handleSidebar({type: 'favorites'})">
                <i class="icon-heart-filled"></i>
                <span>{{ favoritesCount }}</span>
            </button>
            <button
                class="btn btn--md btn--il btn--wild-sand action action--cart"
                :class="{ 'filled' : cartCount > 0 }"
                @click="handleSidebar({type: 'cart'})"
            >
                <i class="icon-cart"></i>
                <span>{{ cartCount }}</span>
            </button>
        </div>
    </nav>
</template>

<script>
export default {
    props: [],

    data() {
        return {};
    },

    computed: {
        favoritesCount() {
            return this.$store.getters.getFavoritesCount;
        },

        cartCount() {
            return this.$store.getters.getCartCount;
        },

        showButton() {
            return (!this.isWebsiteOrigin && this.$route.path !== '/');
        },
    },

    methods: {
        restartApp() {
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            window.history.replaceState(null, '', newUrl);
            window.localStorage.clear();
            if (this.$route.path !== '/') {
                this.$router.push({ name: 'overview' });
                location.reload();
            } else {
                location.reload();
            }
        },
    },
};
</script>

<template>
    <SidebarLayout type="cart">
        <template #title>{{ $t('cart.title') }}</template>

        <p class="body--small" v-html="data.descriptions.reservation"></p>

        <div class="row">
            <div class="col-6" v-for="slug in cart" :key="slug">
                <product-card-simple v-if="$store.getters.getItemById(slug)" :item="$store.getters.getItemById(slug)"></product-card-simple>
            </div>
        </div>

        <template #footer>
            <router-link :to="{ name: setRoute() }" class="btn btn--secondary">{{ $t('optician.choose') }}</router-link>
        </template>
    </SidebarLayout>
</template>

<script>
import SidebarLayout from '@/components/sidebar/SidebarLayout';
import ProductCardSimple from '@/components/cards/productCardSimple';

export default {
    components: {
        SidebarLayout,
        ProductCardSimple,
    },

    computed: {
        data() {
            return this.$store.getters.getData;
        },

        cart() {
            return this.$store.getters.getCart;
        },

        getSingleOpticianView() {
            return this.$store.getters.getSingleOpticianView;
        },
    },

    methods: {
        setRoute() {
            return this.getSingleOpticianView ? 'summary' : 'order';
        },
    },
};
</script>

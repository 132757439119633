<template>
    <div id="map-view"></div>
</template>

<script>
// Leaflet
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// MarkerClusters
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

// Gesture handling
import { GestureHandling } from 'leaflet-gesture-handling';

export default {
    props: [],

    data() {
        return {
            interval: null,
            icon: null,
            map: null,
            markers: null,
            bounds: [],
            centerLatLng: [],
            dataInView: [],
            userMarker: null,
        };
    },

    computed: {
        colors() {
            return this.$store.getters.getColors;
        },

        data() {
            return this.$store.getters.getMapOpticians;
        },

        searchInput() {
            return this.$store.getters.getSearchInput;
        },

        userLocation() {
            return this.$store.getters.getUserLocation;
        },
    },

    methods: {
        initMap() {
            clearInterval(this.interval);
            this.icon = L.icon({
                iconUrl: '/images/marker-icon.png',
                iconSize: [16, 27],
                iconAnchor: [8, 27],
            });
            this.centerLatLng = [52.2189324, 5.4441059]; // Center of netherlands (Nijkerk)
            this.setMap();
            this.map.setView(this.centerLatLng, 8);
            this.bounds.push(this.centerLatLng);
            this.map.fitBounds(this.bounds);
            this.markers = L.markerClusterGroup({
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
            });
            this.renderData();
        },

        setMap() {
            L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
            this.map = L.map('map-view', {
                attributionControl: false,
                scrollWheelZoom: false,
                zoom: 24,
                gestureHandling: true,
            });

            this.map.on('zoomend', () => {
                this.getDataInView();
            });
            this.map.on('dragend', () => {
                this.getDataInView();
            });

            this.map.setView([51.505, -0.09], 13);

            L.tileLayer(
                'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                {
                    maxZoom: 18,
                },
            ).addTo(this.map);
        },

        marker(location, color) {
            const { lat, lng } = location.coordinates;
            return L.marker([lat, lng], {
                icon: this.markerLayout(color),
            });
        },

        markerLayout(customColor) {
            let setColor = this.colors.primary;
            if (customColor) {
                setColor = customColor;
            }

            return L.divIcon({
                iconSize: [28, 34],
                iconAnchor: [14, 36],
                className: 'custom-marker',
                html: '<svg width="28px" height="34px" viewBox="0 0 28 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                    '    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
                    '        <g transform="translate(-6.000000, -3.000000)">' +
                    `            <path fill="${setColor}" d="M20,3 C27.7319864,3 34,9.22731203 34,16.9090908 C34,27.7272727 20,37 20,37 C20,37 6,27.7272727 6,16.9090908 C6,9.22731203 12.2680136,3 20,3 Z M20,11 C17.2385763,11 15,13.2385763 15,16 C15,18.7614237 17.2385763,21 20,21 C22.7614237,21 25,18.7614237 25,16 C25,13.2385763 22.7614237,11 20,11 Z"></path>` +
                    '        </g>' +
                    '    </g>' +
                    '</svg>',
            });
        },

        renderData() {
            // Guard clauses
            if (this.markers) {
                this.markers.clearLayers();
            }

            if (this.data.length === 0) {
                return;
            }

            if (this.bounds.length > 0) {
                this.bounds = [];
            }

            // Loop
            for (let i = 0; i < this.data.length; i += 1) {
                const result = this.data[i];
                const latLng = [result.coordinates.lat, result.coordinates.lng];
                const marker = this.marker(result);

                this.markers.addLayer(marker);
                this.bounds.push(latLng);

                marker.on('click', () => {
                    this.$store.dispatch('setActiveOptician', result.slug);
                });
            }

            // Always get bounds when there is no data
            if (this.bounds.length === 0) {
                this.bounds = [this.centerLatLng];
            }

            // Add markers and bounds to map
            this.map.addLayer(this.markers);
            this.map.fitBounds(this.bounds);
        },

        getDataInView() {
            this.dataInView = [];
            for (let i = 0; i < this.data.length; i += 1) {
                const result = this.data[i];
                if (this.map.getBounds().contains(result.coordinates)) {
                    this.dataInView.push(result);
                }
            }

            this.$store.dispatch('setListOpticians', this.dataInView);
        },

        getMarkersWithinRadius() {
            this.data.sort((a, b) => L.latLng(this.map.getCenter()).distanceTo(a.coordinates) - L.latLng(this.map.getCenter()).distanceTo(b.coordinates));
        },

        setUserLocationWithRadius() {
            const { lat, lng } = this.userLocation.coordinates;
            this.userMarker = this.marker(this.userLocation, '#0a4056').addTo(this.map);
            this.map.setView(new L.LatLng(lat, lng), this.map.getZoom() + 2);
            setTimeout(() => {
                this.getMarkersWithinRadius();
            }, 150);
        },
    },

    mounted() {
        this.initMap();
    },

    watch: {
        data: {
            deep: true,
            handler() {
                this.renderData();
            },
        },

        searchInput: {
            deep: true,
            handler() {
                this.renderData();
            },
        },

        userLocation: {
            deep: true,
            handler() {
                const originalBounds = this.bounds;
                if (Object.keys(this.userLocation).length > 0) {
                    this.setUserLocationWithRadius();
                } else if (this.userMarker) {
                    this.map.removeLayer(this.userMarker);
                    this.userMarker = null;
                    this.map.fitBounds(originalBounds);
                }
            },
        },
    },
};
</script>

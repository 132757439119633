<template>
    <div class="step step--camera">
        <div class="step__content">
            <h4>{{ $t('upload.step_camera') }}</h4>
        </div>
        <div class="upload-wrapper">
            <div class="working-canvas working-canvas--camera" :class="{ 'disabled' : !mediaStream }">
                <div class="camera-overlay">
                    <svg viewBox="0 0 385 385">
                        <path d="M0,0V385H385V0H0ZM283.09,191.86c0,13.73-7.3,26.13-18.41,31.76-2.83,18.63-6.29,31.08-10.54,38.05-8.78,14.33-28.59,39.05-43.33,46.65-.59,.3-1.25,.47-1.91,.47h-32.79c-.67,0-1.33-.17-1.94-.48-15.14-7.92-34.91-32.64-43.5-46.86-4.1-6.99-7.44-19.33-10.26-37.71-11.16-5.62-18.49-18.07-18.49-31.86v-14.53c0-4.84,2.12-9.42,5.69-12.24,2.1-1.66,4.48-2.61,6.95-2.8-.28-4.67-.53-9.38-.78-14.09-1.15-23.77,6.31-46.29,21.02-63.49,14.68-17.16,34.52-26.6,55.89-26.6h3.72c21.35,0,41.2,9.46,55.91,26.64,14.7,17.19,22.16,39.66,20.99,63.27l-.76,14.27c2.4,.22,4.78,1.16,6.86,2.8,3.56,2.82,5.68,7.4,5.68,12.24v14.53Z"/>
                    </svg>
                </div>
                <video ref="camera" autoplay muted playsinline></video>
                <canvas ref="canvas"></canvas>
                <img ref="snapshot" src="" alt="" v-show="mediaStream && preview" />
                <div class="actions">
                    <span class="take-photo-label">{{ $t('photo.default') }}</span>
                    <div class="actions__buttons">
                        <button class="reset-photo" @click="resetSnapshot">
                            <i class="icon-refresh"></i>
                        </button>
                        <button class="take-photo" @click="takeSnapshot"></button>
                        <button class="switch-camera" @click="switchCameraMode" v-if="cameraModesMultiple">
                            <i class="icon-camera-switch"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepCamera',

    props: [
        'cameraEnabled',
        'preview',
        'mediaStream',
        'constraints',
    ],

    data() {
        return {
            supports: navigator.mediaDevices.getSupportedConstraints(),
            cameraModesMultiple: false,
            DOM: {
                camera: null,
                canvas: null,
                snapshot: null,
            },
            mediaStreamLocal: null,
        };
    },

    mounted() {
        if (this.supports.facingMode) {
            this.DOM.camera = this.$refs.camera;
            this.DOM.canvas = this.$refs.canvas;
            this.DOM.snapshot = this.$refs.snapshot;
            if (this.$props.cameraEnabled) {
                this.getCameraModes();
                this.openCamera('user');
            }
        } else {
            alert('This browser does not support facingMode!');
        }
    },

    methods: {
        dataURItoBlob(dataURI) {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);

            for (let i = 0; i < byteString.length; i += 1) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ab], {
                type: mimeString,
            });
        },

        async getCameraModes() {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');
            this.cameraModesMultiple = videoDevices.length > 1;
        },

        async getMediaStream(constraints) {
            try {
                this.mediaStreamLocal = await navigator.mediaDevices.getUserMedia(constraints);
                const video = this.DOM.camera;
                video.srcObject = this.mediaStreamLocal;

                video.onloadedmetadata = () => {
                    video.play();
                };
            } catch (err) {
                throw new Error(err);
            }
        },

        async openCamera(cameraMode) {
            try {
                if (this.$props.mediaStream != null && this.$props.mediaStream.active) {
                    const tracks = this.$props.mediaStream.getVideoTracks();
                    tracks.forEach(track => {
                        track.stop();
                    });
                }

                this.DOM.camera.srcObject = null;
                this.$emit('updateFacingMode', cameraMode);

                await this.getMediaStream(this.$props.constraints);
            } catch (err) {
                throw new Error(err.message);
            }
        },

        switchCameraMode() {
            this.openCamera(this.$props.constraints.video.facingMode === 'user' ? 'environment' : 'user');
        },

        takeSnapshot() {
            const context = this.DOM.canvas.getContext('2d');
            const width = this.DOM.camera.videoWidth;
            const height = this.DOM.camera.videoHeight;

            if (width && height) {
                this.DOM.canvas.width = width;
                this.DOM.canvas.height = height;
                context.drawImage(this.DOM.camera, 0, 0, width, height);
                const dataURL = this.DOM.canvas.toDataURL('image/png');
                this.DOM.snapshot.setAttribute('src', dataURL);
                this.$emit('update:preview', dataURL);
                this.$emit('update:data', this.dataURItoBlob(dataURL));
            } else {
                this.clearSnapshot();
            }
        },

        clearSnapshot() {
            const context = this.DOM.canvas.getContext('2d');
            context.fillRect(0, 0, this.DOM.canvas.width, this.DOM.canvas.height);
            this.DOM.snapshot.setAttribute('src', this.DOM.canvas.toDataURL('image/png'));
        },

        resetSnapshot() {
            this.openCamera('user');
            setTimeout(() => {
                this.clearSnapshot();
                this.$emit('update:preview', null);
                this.$emit('update:data', null);
            }, 250);
        },
    },

    watch: {
        cameraEnabled() {
            this.openCamera('user');
        },
    },
};
</script>

<template>
    <button v-show="!hideBrandBasedOnConcepts"
        class="btn btn-sm btn--tag btn--o btn--mercury"
        :class="{ 'btn--active' : active, 'btn--il' : filter.color }"
        @click="updateFilters">
        <i v-if="filter.color"
            class="btn__color-dot"
            :style="{ 'background-color': filter.color }"
        />
        <span>{{ filter.name }}</span>
        <img
            v-if="filter.media"
            :src="filter.media"
            alt=""
        />
        <i
            v-show="active"
            class="icon-close-alt close-visual"
        />
    </button>
</template>

<script>
export default {
    props: ['filter', 'parent'],

    data() {
        return {
            active: false,
            hideBrandBasedOnConcepts: false,
        };
    },

    computed: {
        numberOfActiveFilters() {
            return this.$store.getters.getNumberOfActiveFilters;
        },
        activeFilters() {
          return this.$store.getters.getActiveFilters;
        },
    },

    mounted() {
        this.checkIsActive();
        this.isBrandConcept = this.deleteFilterBasedOnBrandAndConcept();
    },

    watch: {
        numberOfActiveFilters() {
            if (this.filter) {
                this.checkIsActive();
                this.deleteFilterBasedOnBrandAndConcept();
            }
        },
    },

    methods: {
        updateFilters() {
            this.active = !this.active;
            const parent = this.parent;
            const filter = this.filter;
            this.$store.dispatch('changeFiltersAsync', { parent, filter });
        },

        checkIsActive() {
            const parent = this.parent;
            const filter = this.filter.slug;
            this.$store.dispatch('isFilterActive', { parent, filter })
                .then(checkActive => {
                    this.active = checkActive;
                });
        },
      deleteFilterBasedOnBrandAndConcept() {
          const parent = this.parent;

          if (parent === 'brands') {
            const concepts = this.filter.concepts;
            const hasActiveFilterConcepts = Object.prototype.hasOwnProperty.call(this.activeFilters, 'concepts');

            if (concepts && hasActiveFilterConcepts) {
              const activeConcepts = this.activeFilters.concepts;
              const activeConceptsKeys = Object.keys(activeConcepts);

              const found = concepts.some(r => activeConceptsKeys.includes(r));
              if (!found) {
                this.hideBrandBasedOnConcepts = true;
              } else {
                this.hideBrandBasedOnConcepts = false;
              }
            } else {
              this.hideBrandBasedOnConcepts = false;
            }
          } else {
            this.hideBrandBasedOnConcepts = false;
          }

        return true;
      },
    },
};
</script>

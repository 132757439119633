<template>
    <div class="tabs__wrapper">
        <ul class='tabs__header style-none'>
            <li v-for='(tab, index) in tabs'
                :key='tab.title'
                :class='{ "tab__selected" : index === selectedIndex }'>
                <span @click='selectTab(index)'>{{ tab.title }}</span>
            </li>
        </ul>
        <div class="tabs__inner" :style="{ height: tabHeight + 'px' }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedIndex: 0,
            tabs: [],
            tabHeight: 0,
        };
    },

    created() {
        this.tabs = this.$children;
    },

    mounted() {
        this.selectTab(0);
        this.setTabHeight();
        this.$on('setHeight', (value) => {
            this.tabHeight = value;
        });
    },

    methods: {
        selectTab(i) {
            this.selectedIndex = i;
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);
            });
        },

        setTabHeight() {
            this.tabs.forEach(child => {
                if (child.tabHeight > this.tabHeight) {
                    this.tabHeight = child.tabHeight;
                }
            });
        },
    },
};
</script>

<template>
    <div class="sidebar--layout" :class="'sidebar-type--' + type" :data-sidebar="type">
        <div class="header">
            <slot name="header"></slot>
            <button class="btn btn--io btn--wild-sand btn--close btn--sm" @click="handleSidebar({ type, action: 'close' })">
                <i class="icon-close"></i>
            </button>
        </div>

        <h2 class="title" v-if="hasData('title')">
            <slot name="title"></slot>
        </h2>

        <div class="above-content" v-if="hasData('above-content')">
            <slot name="above-content"></slot>
        </div>

        <div class="overflow-area">
            <div class="content" v-if="hasData('default')">
                <slot name="default"></slot>
            </div>
            <div class="footer" v-if="hasData('footer')">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['type'],

    methods: {
        hasData(name) {
            return !!this.$slots[name];
        },
    },
};
</script>

declare global {
    interface Window {
        offsetHeight: number;
    }
}

export default function () {
    const reCalculateHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    let windowInnerHeight = window.offsetHeight;

    window.addEventListener('resize', () => {
        if (window.offsetHeight !== windowInnerHeight) {
            reCalculateHeight();
            windowInnerHeight = window.offsetHeight;
        }
    });

    window.addEventListener('orientationchange', () => {
        setTimeout(() => {
            reCalculateHeight();
        }, 150);
    });

    reCalculateHeight();
}

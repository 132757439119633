import getParents from '../util/getParents';
import { scrollToY } from '../util/scrollY';

let selectedFirst = false;

const validateDate = (date) => {
    let valid = true;
    const re = /^(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}$/;

    if (!re.test(date.value)) {
        valid = false;
    }

    return valid;
};

const validateEmail = (email) => {
    let valid = true;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(email.value)) {
        valid = false;
    }

    return valid;
};

const buildError = (element) => {
    const holder = getParents(element, '.form-group');

    if (!holder || holder.querySelector('.form-group__message--error') !== null) {
        return false;
    }

    holder.classList.remove('form-group--valid');
    holder.classList.add('form-group--invalid');
};

const requiredInputs = (form) => {
    const inputs = form.querySelectorAll('[required], [aria-required="true"], [type=email]');
    const radioButtonsGroups = form.querySelectorAll('.form-group--radio');
    const errors = [];
    let validForm = true;

    if (radioButtonsGroups.length > 0) {
        Array.from(radioButtonsGroups).forEach((radioButtonsGroup) => {
            const radioButtons = radioButtonsGroup.querySelectorAll('input[type="radio"][required]');
            if (!radioButtons.length > 0) return;
            Array.from(radioButtons).forEach((radio) => {
                let isChecked = false;

                for (let i = 0; i < radioButtons.length; i += 1) {
                    if (radioButtons[i].checked) {
                        isChecked = true;
                        break;
                    }
                }
                if (!isChecked) {
                    buildError(radio);
                    errors.push('error checked');
                }
            });
        });
    }

    Array.from(inputs).forEach((input) => {
        if ((input.value === '' && input.type !== 'radio') || (input.type === 'checkbox' && !input.checked)) {
            buildError(input);
            selectedFirst = true;
            errors.push('error empty');
        }

        if (input.type === 'email') {
            if (!validateEmail(input)) {
                errors.push('error email');
            }
        }

        if (input.type === 'text' && input.name.includes('day')) {
            if (!validateDate(input)) {
                errors.push('error date');
            }
        }

        if (input.type === 'date' && input.value === '') {
            if (!validateDate(input)) {
                errors.push('error date');
            }
        }

        if (input.type === 'password' && (input.value === '' || input.value.length < 8)) {
            errors.push('error password');
        }
    });

    if (errors.length !== 0) {
        validForm = false;
    }

    return validForm;
};

const checkErrorOnInput = (button, form) => {
    const inputs = form.querySelectorAll('[required], [aria-required="true"], [type=email]');
    const radioButtons = form.querySelectorAll('input[type="radio"][required]');

    const buttonVisibility = (valid, input, holder) => {
        if (!valid) {
            buildError(input);
        } else {
            holder.classList.remove('form-group--invalid');
            holder.classList.add('form-group--valid');
        }

        // check if there are any invalid fields left
        const error = form.querySelectorAll('.form-group--invalid');

        if (button) {
            button.disabled = error.length !== 0;
        }
    };

    if (radioButtons.length > 0) {
        Array.from(radioButtons).forEach((radio) => {
            radio.addEventListener('input', () => {
                const holder = getParents(radio, '.form-group');
                let valid = true;
                let isChecked = false;

                for (let i = 0; i < radioButtons.length; i += 1) {
                    if (radioButtons[i].checked) {
                        isChecked = true;
                        break;
                    }
                }

                if (!isChecked) {
                    valid = false;
                }

                requiredInputs(form);
                buttonVisibility(valid, radio, holder);
            });
        });
    }

    Array.from(inputs).forEach((input) => {
        if (input.tagName === 'SELECT') {
            input.nextElementSibling.addEventListener('click', () => {
                if (selectedFirst) {
                    const holder = getParents(input, '.wpcf7-form-control-wrap');
                    let valid = true;

                    if (input.value === '') {
                        valid = false;
                    }

                    // requiredInputs(form);
                    buttonVisibility(valid, input, holder);
                }
            });
        } else {
            input.addEventListener('input', () => {
                const holder = getParents(input, '.form-group');
                let valid = true;

                if ((input.value === '' && input.type !== 'radio') || (input.type === 'checkbox' && !input.checked)) {
                    valid = false;
                }

                if (input.type === 'email') {
                    valid = validateEmail(input);
                }

                if (input.type === 'text' && input.name.includes('day')) {
                    valid = validateDate(input);
                }

                if (input.type === 'date' && input.value === '') {
                    valid = false;
                }

                if (input.type === 'password' && (input.value === '' || input.value.length < 8)) {
                    valid = false;
                }

                // requiredInputs(form);
                buttonVisibility(valid, input, holder);
            });
        }
    });
};

const scrollFirstError = (form) => {
    const firstError = form.querySelector('.form-group--invalid');
    const navbar = document.querySelector('.navbar');
    if (navbar && form && firstError) {
        const pagePartOffsetTop = (firstError.getBoundingClientRect().top + window.scrollY) - navbar.offsetHeight - 5;
        scrollToY(pagePartOffsetTop, 1500, 'easeInOutQuint');
    }
};

const validateOnSubmit = () => {
    const form = document.querySelectorAll('form');

    if (form.length === 0) {
        return;
    }

    Array.from(form).forEach((el) => {
        const button = el.querySelector('[type="submit"]');

        // Check if form is valid on input
        checkErrorOnInput(button, el);

        if (!button) {
            return;
        }

        // Check if form is valid on click
        button.addEventListener('click', (ev) => {
            const validateIt = requiredInputs(el);
            if (!validateIt) {
                ev.preventDefault();
            }

            return false;
        });
    });
};

const validate = () => {
    validateOnSubmit();
};

export {
    validate as default,
    requiredInputs,
    scrollFirstError,
    checkErrorOnInput,
    buildError,
    validateEmail,
    validateDate,
};

<template>
    <div class="image-view">
        <figure class="image-wrapper">
            <img :src="imageSource" alt="">
        </figure>
    </div>
</template>

<script>
export default {
    computed: {
        imageSource() {
            return this.$route.meta.imageSource;
        },
    },
};
</script>

<template>
    <div class="accordion__item">
        <div class="accordion__term">
            <div>
                <p>{{ item.name }}</p>
                <p class="body--small">{{ item.address.street }} {{ item.address.number }}, {{ item.address.postal_code }} {{ item.address.city }}</p>
            </div>
            <button v-if="accordionType ==='selectOptician'" class="btn btn--md"
                    :class="isActive(item.slug) ? 'btn--primary' : 'btn--mercury'" @click.stop="selectOptician(item)">
                <span v-if="isActive(item.slug)">{{ $t('optician.selected') }}</span>
                <span v-else>{{ $t('optician.choose') }}</span>
            </button>

            <router-link v-if="accordionType === 'summary' && !getSingleOpticianView" :to="{ name: 'order' }" class="btn btn--sm btn--mercury">
                {{ $t('optician.chooseDifferent') }}
            </router-link>
        </div>
        <div class="accordion__body collapse">
            <div class="accordion__description">
                <div v-if="item.opening_hours">
                    <table class="openinghours-table">
                        <tr v-for="(value, key, index) in item.opening_hours" :key="key" :class="{ 'current-day' : key === getDayOfWeek() }">
                            <td class="day">{{ getShortNameWeek()[index] }}</td>
                            <td class="hours">{{ setOpeningHours(value) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item', 'accordionType'],

    computed: {
        activeOptician() {
            return this.$store.getters.getActiveOptician;
        },

        getSingleOpticianView() {
            return this.$store.getters.getSingleOpticianView;
        },

        days() {
            return Object.keys(this.$props.item.opening_hours);
        },
    },

    methods: {
        selectOptician(item) {
            this.$store.dispatch('setActiveOptician', item.slug);
        },

        isActive(slug) {
            if (this.activeOptician) {
                return this.activeOptician === slug;
            }
        },

        setOpeningHours(value) {
            if (!value || !(/\d/.test(value))) {
                return this.$t('message.closed');
            }

            return `${value} ${this.$t('message.hour').toLowerCase()}`;
        },

        getDayOfWeek() {
            return this.days[new Date().getDay() - (new Date().getDay() === 0 ? 6 : 1)];
        },

        getShortNameWeek() {
            return this.days.map((a) => a.charAt(0).toUpperCase() + a.substring(1, 2));
        },
    },
};
</script>

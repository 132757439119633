<template>
    <div id="main-widget-container" :class="$route.name">
        <!-- Left side -->
        <div class="view-wrapper view--media" v-if="!(isTouchScreen && $route.name === 'overview')">
            <emptyView v-show="!imagePath && ($route.name === 'overview' || $route.name === 'single' && fittingRoomView !== 'preview')"></emptyView>
            <router-view name="MediaView" v-show="(fittingRoomView !== '' && fittingRoomView !== null || imagePath) || !['overview', 'single'].includes($route.name)"></router-view>
        </div>
        <!-- Right side -->
        <div class="view-wrapper view--content">
            <navigation-bar-component></navigation-bar-component>
            <router-view name="ContentView"></router-view>
        </div>
        <!-- Sidebars -->
        <cart-sidebar></cart-sidebar>
        <favorites-sidebar></favorites-sidebar>
        <user-face-sidebar></user-face-sidebar>
        <models-sidebar></models-sidebar>
        <filter-sidebar></filter-sidebar>
        <pupil-distance-sidebar></pupil-distance-sidebar>
    </div>
</template>

<script>
import height from '@scripts/util/height';
import emptyView from '@/views/Pages/mediaView/EmptyView';
import navigationBarComponent from '@/components/navigation/navigationBarComponent';
import cartSidebar from '@/components/sidebar/CartSidebar';
import favoritesSidebar from '@/components/sidebar/FavoritesSidebar';
import userFaceSidebar from '@/components/sidebar/UserFaceSidebar';
import modelsSidebar from '@/components/sidebar/ModelsSidebar';
import filterSidebar from '@/components/sidebar/FilterSidebar';
import pupilDistanceSidebar from '@/components/sidebar/PupliDistanceSidebar';

height();

export default {
    components: {
        emptyView,
        navigationBarComponent,
        cartSidebar,
        favoritesSidebar,
        userFaceSidebar,
        modelsSidebar,
        filterSidebar,
        pupilDistanceSidebar,
    },

    computed: {
        imagePath() {
            return this.$store.getters.getImagePath;
        },

        fittingRoomView() {
            return this.$store.getters.getFittingRoom.selected;
        },
    },
};
</script>

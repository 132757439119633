import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '../store/index';

// ContentViews
import OverviewView from '../views/Pages/contentView/OverviewView.vue';
import SingleView from '../views/Pages/contentView/SingleView.vue';
import OpticianView from '../views/Pages/contentView/order/OpticianView.vue';
import SummaryView from '../views/Pages/contentView/order/SummaryView.vue';
import ThankYouView from '../views/Pages/contentView/order/ThankYouView.vue';
import PersonalInformationView from '../views/Pages/contentView/order/PersonalInformationView.vue';

// MediaViews
import FittingRoomView from '../views/Pages/mediaView/FittingRoomView.vue';
import ImageView from '../views/Pages/mediaView/ImageView.vue';
import MapView from '../views/Pages/mediaView/MapView.vue';

Vue.use(VueRouter);

// Routes
const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'overview',
        components: {
            default: OverviewView,
            MediaView: FittingRoomView,
            ContentView: OverviewView,
        },
    },
    {
        path: '/:slug',
        name: 'single',
        components: {
            default: SingleView,
            MediaView: FittingRoomView,
            ContentView: SingleView,
        },
    },
    {
        path: '/order/optician',
        name: 'order',
        components: {
            default: OpticianView,
            MediaView: MapView,
            ContentView: OpticianView,
        },
    },
    {
        path: '/order/summary',
        name: 'summary',
        meta: {
            imageSource: require('@/assets/male-model.jpg'),
        },
        components: {
            default: SummaryView,
            MediaView: ImageView,
            ContentView: SummaryView,
        },
    },
    {
        path: '/order/personal-information',
        name: 'personalinformation',
        meta: {
            imageSource: require('@/assets/male-model.jpg'),
        },
        components: {
            default: PersonalInformationView,
            MediaView: ImageView,
            ContentView: PersonalInformationView,
        },
    },
    {
        path: '/order/complete',
        name: 'thankyou',
        meta: {
            imageSource: require('@/assets/thankyou.png'),
        },
        components: {
            default: ThankYouView,
            MediaView: ImageView,
            ContentView: ThankYouView,
        },
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    store.dispatch('handleSidebar', {
        action: 'close',
    });
    if (to.name === 'overview') {
        let setView = null;
        if (store.getters.getUserHasUploadedImage) {
            setView = 'user';
        } else if (store.getters.imagePath !== '') {
            setView = 'model';
        }
        store.dispatch('changeFittingRoomView', setView);
        store.dispatch('showUI', true);
    }
    next();
});

export default router;
